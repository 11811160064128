// driverSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Driver, DriversResponse } from "../interfaces";

export interface driverState {
  order: "asc" | "desc";
  orderBy: keyof Driver;
  selected: number[];
  page: number;
  rowsPerPage: number;
  driversData: Driver[];
  total: number;
}

const initialState: driverState = {
  order: "asc",
  orderBy: "createdAt",
  selected: [],
  page: 1,
  rowsPerPage: 10,
  driversData: [],
  total: 0,
};

const driverSlice = createSlice({
  name: "driver",
  initialState,
  reducers: {
    setOrder: (state, action: PayloadAction<"asc" | "desc">) => {
      state.order = action.payload;
    },
    setOrderBy: (state, action: PayloadAction<keyof Driver>) => {
      state.orderBy = action.payload;
    },
    setSelected: (state, action: PayloadAction<number[]>) => {
      state.selected = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action: PayloadAction<number>) => {
      state.page = 1;
      state.rowsPerPage = action.payload;
    },
    setDriverData: (state, action: PayloadAction<DriversResponse>) => {
      const { drivers, total } = action.payload;
      if (total) {
        state.total = total;
      }
      state.driversData = drivers;
    },
    updateDriverData: (state, action: PayloadAction<Driver[]>) => {
      state.driversData = action.payload;
    },
    deletedriver: (state, action: PayloadAction<number>) => {
      const newdriverDate = state.driversData.filter(
        (el) => el.id !== action.payload
      );
      state.driversData = newdriverDate;
      state.total = state.total - 1;
    },
  },
});

export const {
  setOrder,
  setOrderBy,
  setSelected,
  setPage,
  setRowsPerPage,
  setDriverData,
  deletedriver,
  updateDriverData,
} = driverSlice.actions;

export default driverSlice.reducer;
