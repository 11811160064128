import { Box,  Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import DashboardCard from "../components/DashboardCard";
import { Stack } from "@mui/system";
import DashboardTile from "../components/DashboardTile";
import { restService } from "../services/rest.service";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import DeliveryDiningOutlinedIcon from "@mui/icons-material/DeliveryDiningOutlined";
import { trackPromise } from "react-promise-tracker";

const dummy = [
  {
    title: "Seller Details",
    values: [
      { title: "Total Sellers", value: 123, code: "primary" },
      { title: "Verified Sellers", value: 123, code: "success" },
      { title: "Unverified Sellers", value: 123, code: "warning" },
    ],
    key: "yoyo",
  },
  {
    title: "Driver Details",
    values: [
      { title: "Total Sellers", value: 123, code: "primary" },
      { title: "Verified Sellers", value: 123, code: "success" },
      { title: "Unverified Sellers", value: 123, code: "warning" },
    ],
    key: "hoho",
  },
];

interface Seller {
  icon: any;
  title: string;
  value: number;
  colorCode: string;
}
const Dashboard = () => {
  const [seller, setSeller] = useState<Seller[]>([]);
  const getSeller = async () => {
    try {
      const keyMapping = {
        unverifiedSellersCount: "Unverified Sellers",
        verifiedSellersCount: "Verified Sellers",
        unverifiedDriversCount: "Unverified Drivers",
        verifiedDriversCount: "Verified Drivers",
      };
      const colorMapping = {
        unverifiedSellersCount: "warning",
        verifiedSellersCount: "success",
        unverifiedDriversCount: "warning",
        verifiedDriversCount: "success",
      };
      const iconMapping = {
        unverifiedSellersCount: <StorefrontOutlinedIcon />,
        verifiedSellersCount: <StorefrontOutlinedIcon />,
        unverifiedDriversCount: <DeliveryDiningOutlinedIcon />,
        verifiedDriversCount: <DeliveryDiningOutlinedIcon />,
      };
      const response = await trackPromise(restService.userCountApi());
      const sellerTile: Seller[] = response.map((obj: any) => {
        const { message, ...rest } = obj.data;
        const valueKey = Object.keys(rest)[0] as keyof typeof colorMapping;
        const title = keyMapping[valueKey];
        const colorCode = colorMapping[valueKey];
        return {
          icon: iconMapping[valueKey],
          title,
          value: rest[valueKey],
          colorCode,
        };
      });
      setSeller(sellerTile);
    } catch (error: any) {
      throw error.response.data.message;
    } finally {
    }
  };
  useEffect(() => {
    getSeller();
  }, []);
  return (
    <Box>
      <Stack gap={2}>
        <Grid container spacing={2}>
          {seller.map((seller) => (
            <Grid key={seller.title} item xs="auto">
              <DashboardTile key={seller.title} {...seller} />
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={2}>
          {dummy.map((el) => (
            <Grid key={el.title} item xs="auto">
              <DashboardCard {...el} />
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Box>
  );
};

export default Dashboard;
