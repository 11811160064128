// src/ImageDescriptionRow.tsx
import React from "react";
import { Container, Typography, Paper, Box } from "@mui/material";

interface ImageDescriptionRowProps {
  imageSrc: string;
  description?: string;
  reverse?: boolean;
  title: string;
}

const ImageDescriptionRow: React.FC<ImageDescriptionRowProps> = ({
  imageSrc,
  title,
  description,
  reverse = false,
}) => {
  return (
    <Container>
      <Paper
        style={{
          display: "flex",
          flexDirection: reverse ? "row-reverse" : "row",
          padding: "16px",
          alignItems: "center",
        }}
      >
        <Box>
          <img src={imageSrc} alt="Description" style={{ width: 500 }} />
        </Box>
        <Box flexGrow={1} ml={2}>
          <Typography variant="h5" className="poppins-bold">
            {title}
          </Typography>
          <Typography variant="caption">{description}</Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default ImageDescriptionRow;
