import React from "react";
import { Container, Box, Typography } from "@mui/material";
import GooglePlayButton from "./GooglePlayButton";
import AppStoreButton from "./AppStoreButton";

function DownloadBanner() {
  return (
    <Container>
      <Box
        display={"flex"}
        padding={4}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Box display={"flex"} flexDirection={"column"} gap={"30px"} flex={0.5}>
          <Typography
            variant="h4"
            color={"primary"}
            fontWeight={"bold"}
            className="poppins-extra-bold"
          >
            Have you got your App ?
          </Typography>
          <Typography variant="subtitle1" color={"primary"} fontWeight={"500"}>
            Get your app now , available on the Apple App Store and Google Play
            Store
          </Typography>
          <Box display={"flex"} gap={"20px"}>
            <GooglePlayButton />
            <AppStoreButton />
          </Box>
        </Box>
        <img src={require("./../assets/mobile.png")} width={300} />
      </Box>
    </Container>
  );
}

export default DownloadBanner;
