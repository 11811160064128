import React from "react";
import { Container, Typography, Paper, Link, Stack, Box } from "@mui/material";

const TermsConditions = () => {
  return (
    <Stack>
      <Stack paddingX={"40px"} gap={4}>
        <Box
          display={"flex"}
          justifyContent={"center"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <img src={require("./../assets/logo.png")} width={300} />
          <Typography
            variant="overline"
            fontSize={"32px"}
            className="playfair-font"
          >
            TERMS AND CONDITIONS
          </Typography>
        </Box>
        <Container maxWidth="sm">
          <Paper elevation={3} style={{ padding: "20px" }}>
            <Typography variant="h5">Privacy Policy</Typography>
            <Typography variant="subtitle1">
              Hungrezy: Terms of Use Last updated on 27th October, 2023
            </Typography>

            <Typography variant="body1">
              This document is an electronic record in terms of Information
              Technology Act, 2000 and rules there under as applicable and the
              amended provisions pertaining to electronic records in various
              statutes as amended by the Information Technology Act, 2000. This
              document is published in accordance with the provisions of Rule 3
              (1) of the Information Technology (Intermediaries guidelines)
              Rules, 2011 that require publishing the rules and regulations,
              privacy policy and Terms of Use for access or usage of
              www.hungrezy.in website and Hungrezy application for mobile and
              handheld devices.
            </Typography>
          </Paper>
        </Container>
      </Stack>
    </Stack>
  );
};

export default TermsConditions;
