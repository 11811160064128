// theme.ts
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#21335b", // Replace with your desired primary color
    },
    warning: {
      main: "#ffcb66", // Replace with your desired warning color
    },
    background: {
      default: "#f8f8f8",
    },
  },
});

export default theme;
