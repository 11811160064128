// SellerForm.tsx

import React, { useState } from "react";
import {
  TextField as MuiTextField,
  FormControl,
  Select as MuiSelect,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Stack,
  FormLabel,
  Typography,
  styled,
} from "@mui/material";
import ImageUploader from "./ImageUploader";
import { SellerAllData } from "../interfaces";
import { restService } from "../services/rest.service";
import { commonService } from "../services/common.service";
import { useSeller } from "../pages/RestaurantPage";
import { Create } from "@mui/icons-material";
import { trackPromise } from "react-promise-tracker";

const TextField = styled(MuiTextField)({
  backgroundColor: "white",
});
const Select = styled(MuiSelect)({
  backgroundColor: "white",
});
const RestaurantForm: React.FC = () => {
  const { seller: prefillData } = useSeller();
  const [editable, setEditable] = useState(false);
  const [formData, setFormData] = useState({
    id: prefillData.id || 0,
    phone: prefillData.phone || "",
    email: prefillData.email || "",
    language: prefillData.language || "en",
    name: prefillData.name || "",
    restaurantName: prefillData.restaurantName || "",
    tagLine: prefillData.tagLine || "",
    address: prefillData.address || "",
    instaProfile: prefillData.instaProfile || "",
    serveType: prefillData.serveType || "both",
    bestChoices: prefillData.bestChoices || [],
    isVerified: prefillData.isVerified || false,
    menuImage: prefillData.menuImage || "",
    restaurantImage: prefillData.restaurantImage || "",
    coverImage: prefillData.coverImage || "",
    isActive: prefillData.isActive || false,
    onlineStatus: prefillData.onlineStatus || false,
    activeDeviceToken: prefillData.activeDeviceToken || "",
    storeType: prefillData.storeType || "food",
    operationalHours: prefillData.operationalHours || {
      openingTime: "",
      closingTime: "",
      openingDays: [],
    },
    sellerBankDetails: prefillData.sellerBankDetails || {
      bankProof: "",
      accountBranch: "",
      accountNumber: "",
      accountBankIFSC: "",
      accountBankName: "",
      accountHolderName: "",
    },
    sellerLicenseDetails: prefillData.sellerLicenseDetails || {
      gstDetails: {
        gstCharge: true,
        gstNumber: "",
        gstCertificate: "",
      },
      panDetails: {
        name: "",
        address: "",
        panNumber: "",
        panCardImage: "",
      },
      fssaiDetails: {
        expiry: "",
        fssaiNumber: "",
        fssaiImages: "",
      },
    },
  });
  const [terms, setTerms] = useState(false);
  const handleArrayChange = (field: keyof SellerAllData, value: string) => {
    const array = value.split(",");
    setFormData((prevData) => ({
      ...prevData,
      [field]: array,
    }));
  };
  const handleChange = (key: keyof SellerAllData, value: string) => {
    setFormData((prevDate) => ({
      ...prevDate,
      [key]: value,
    }));
  };

  const handleOperationHoursChange = (
    key: keyof typeof formData.operationalHours,
    value: string | string[]
  ) => {
    console.log(value);
    setFormData((prevData) => ({
      ...prevData,
      operationalHours: {
        ...prevData.operationalHours,
        [key]: value,
      },
    }));
  };

  const handleBankDetailsChange = (
    key: keyof typeof formData.sellerBankDetails,
    value: string
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      sellerBankDetails: {
        ...prevData.sellerBankDetails,
        [key]: value,
      },
    }));
  };
  const handleGstDetailsChange = (
    key: keyof typeof formData.sellerLicenseDetails.gstDetails,
    value: string
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      sellerLicenseDetails: {
        ...prevData.sellerLicenseDetails,
        gstDetails: {
          ...prevData.sellerLicenseDetails.gstDetails,
          [key]: value,
        },
      },
    }));
  };
  const handleFssaiDetailsChange = (
    key: keyof typeof formData.sellerLicenseDetails.fssaiDetails,
    value: string
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      sellerLicenseDetails: {
        ...prevData.sellerLicenseDetails,
        fssaiDetails: {
          ...prevData.sellerLicenseDetails.fssaiDetails,
          [key]: value,
        },
      },
    }));
  };
  const handlePanDetailsChange = (
    key: keyof typeof formData.sellerLicenseDetails.panDetails,
    value: string
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      sellerLicenseDetails: {
        ...prevData.sellerLicenseDetails,
        panDetails: {
          ...prevData.sellerLicenseDetails.panDetails,
          [key]: value,
        },
      },
    }));
  };
  const handleTermsChange = (flag: boolean) => {
    setTerms(flag);
  };
  const handleSubmit = () => {
    updateSeller(formData);
  };
  const updateSeller = (data: Partial<SellerAllData>) => {
    trackPromise(restService
      .updateSeller(data))
      .then((res) => {
        setFormData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Stack gap={1} alignItems={"center"}>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item xs>
          <Typography variant={"h5"}>Basic Details</Typography>
        </Grid>
        <Button
          onClick={() => setEditable((prev) => !prev)}
          variant={"text"}
          startIcon={<Create />}
        >
          {editable ? "Disable" : "Edit"}
        </Button>
        <Grid item xs={12}>
          <FormLabel disabled={!editable} component="legend">
            Restaurant Name
          </FormLabel>
          <TextField
            disabled={!editable}
            placeholder="Enter Restaurant Name"
            name="restaurantName"
            fullWidth
            value={formData.restaurantName}
            onChange={(e) => handleChange("restaurantName", e.target.value)}
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          <FormLabel disabled={!editable} component="legend">
            Owner Name
          </FormLabel>
          <TextField
            disabled={!editable}
            placeholder="Enter Owner Name"
            name="name"
            fullWidth
            value={formData.name}
            onChange={(e) => handleChange("name", e.target.value)}
            margin="normal"
          />
        </Grid>
        <Grid item xs={6}>
          <FormLabel disabled={!editable} component="legend">
            Phone Number
          </FormLabel>
          <TextField
            disabled={!editable}
            placeholder="Enter Phone  Number"
            name="phone"
            fullWidth
            value={formData.phone}
            onChange={(e) => handleChange("phone", e.target.value)}
            required
            margin="normal"
          />
        </Grid>
        <Grid item xs={6}>
          <FormLabel disabled={!editable} component="legend">
            Email
          </FormLabel>
          <TextField
            disabled={!editable}
            placeholder="Enter Email"
            name="email"
            fullWidth
            value={formData.email}
            onChange={(e) => handleChange("email", e.target.value)}
            margin="normal"
          />
        </Grid>
        <Grid item xs={6}>
          <FormLabel disabled={!editable} component="legend">
            Tagline
          </FormLabel>
          <TextField
            disabled={!editable}
            fullWidth
            placeholder="Enter Tagline"
            name="tagLine"
            value={formData.tagLine}
            onChange={(e) => handleChange("tagLine", e.target.value)}
            margin="normal"
          />
        </Grid>

        <Grid item xs={6}>
          <FormLabel disabled={!editable} component="legend">
            Address
          </FormLabel>
          <TextField
            disabled={!editable}
            fullWidth
            placeholder="Address"
            name="address"
            value={formData.address}
            onChange={(e) => handleChange("address", e.target.value)}
            margin="normal"
          />
        </Grid>

        <Grid item xs={4}>
          <FormControl
            component="fieldset"
            margin="normal"
            disabled={!editable}
          >
            <FormLabel disabled={!editable} component="legend">
              Serve Type
            </FormLabel>
            <RadioGroup
              aria-label="serveType"
              name="serveType"
              value={formData.serveType}
              onChange={(e) => handleChange("serveType", e.target.value)}
              row // Display radios horizontally
            >
              <FormControlLabel value="both" control={<Radio />} label="Both" />
              <FormControlLabel
                value="dineIn"
                control={<Radio />}
                label="Dine-In"
              />
              <FormControlLabel
                value="delivery"
                control={<Radio />}
                label="Delivery"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={8}>
          <FormControl fullWidth margin="normal">
            <FormLabel disabled={!editable} htmlFor="language">
              Language
            </FormLabel>
            <Select
              disabled={!editable}
              name="language"
              value={formData.language}
              onChange={(e) =>
                handleChange("language", e.target.value as string)
              }
            >
              <MenuItem value="en">English</MenuItem>
              <MenuItem value="es">Spanish</MenuItem>
              {/* Add more language options */}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl
            component="fieldset"
            margin="normal"
            disabled={!editable}
          >
            <FormLabel disabled={!editable} component="legend">
              Store Type
            </FormLabel>
            <RadioGroup
              aria-label="storeType"
              name="storeType"
              value={formData.storeType}
              onChange={(e) => handleChange("storeType", e.target.value)}
              row // Display radios horizontally
            >
              <FormControlLabel value="food" control={<Radio />} label="food" />
              <FormControlLabel
                value="grocery"
                control={<Radio />}
                label="grocery"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormLabel disabled={!editable} component="legend">
            Instagram Profile
          </FormLabel>
          <TextField
            disabled={!editable}
            fullWidth
            placeholder="Instagram Profile"
            name="instaProfile"
            value={formData.instaProfile}
            onChange={(e) => handleChange("instaProfile", e.target.value)}
            margin="normal"
          />
        </Grid>
        <Grid item xs={4}>
          <FormLabel disabled={!editable} component="legend">
            Best Choices (comma separated)
          </FormLabel>

          <TextField
            disabled={!editable}
            fullWidth
            placeholder="Best Choices (comma separated)"
            name="bestChoices"
            value={formData.bestChoices.join(",")}
            onChange={(e) => handleArrayChange("bestChoices", e.target.value)}
            margin="normal"
          />
        </Grid>
        <Grid item xs={6}>
          <FormLabel disabled={!editable} component="legend">
            Opening Time
          </FormLabel>
          <TextField
            disabled={!editable}
            placeholder="Opening Time"
            type="time"
            name="openingTime"
            value={commonService.extractTimeFromTimestamp(
              formData.operationalHours.openingTime
            )}
            onChange={(e) =>
              handleOperationHoursChange(
                "openingTime",
                commonService.convertTimeToTimestamp(e.target.value)
              )
            }
            fullWidth
            margin="normal"
          />
        </Grid>

        <Grid item xs={6}>
          <FormLabel disabled={!editable} component="legend">
            Closing Time
          </FormLabel>
          <TextField
            disabled={!editable}
            placeholder="Closing Time"
            type="time"
            name="closingTime"
            value={commonService.extractTimeFromTimestamp(
              formData.operationalHours.closingTime
            )}
            onChange={(e) =>
              handleOperationHoursChange(
                "closingTime",
                commonService.convertTimeToTimestamp(e.target.value)
              )
            }
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <FormLabel disabled={!editable} component="legend" sx={{ mb: 1 }}>
              Opening Days
            </FormLabel>
            <Select
              disabled={!editable}
              fullWidth
              placeholder="Opening Days"
              name="openingDays"
              value={formData.operationalHours.openingDays}
              onChange={(e) =>
                handleOperationHoursChange(
                  "openingDays",
                  e.target.value as string[]
                )
              }
              multiple
              renderValue={(selected) => (selected as string[]).join(", ")}
            >
              {[
                "sunday",
                "monday",
                "tuesday",
                "wednesday",
                "thursday",
                "friday",
                "saturday",
              ].map((day) => (
                <MenuItem key={day} value={day}>
                  <Checkbox
                    checked={formData.operationalHours.openingDays.includes(
                      day
                    )}
                  />
                  {day.charAt(0).toUpperCase() + day.slice(1)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={"h5"}>Bank Details</Typography>
        </Grid>
        <Grid item xs={12}>
          <FormLabel disabled={!editable} htmlFor="accountNumber">
            Account Number
          </FormLabel>
          <TextField
            disabled={!editable}
            placeholder="Account Number"
            variant="outlined"
            fullWidth
            value={formData.sellerBankDetails.accountNumber}
            onChange={(e) =>
              handleBankDetailsChange("accountNumber", e.target.value)
            }
            name="accountNumber"
            margin="normal"
          />
        </Grid>

        <Grid item xs={6}>
          <FormLabel disabled={!editable} htmlFor="accountBranch">
            Account Branch
          </FormLabel>
          <TextField
            disabled={!editable}
            placeholder="Account Branch"
            variant="outlined"
            fullWidth
            value={formData.sellerBankDetails.accountBranch}
            onChange={(e) =>
              handleBankDetailsChange("accountNumber", e.target.value)
            }
            name="accountBranch"
            margin="normal"
          />
        </Grid>
        <Grid item xs={6}>
          <FormLabel disabled={!editable} htmlFor="accountHolderName">
            Account Holder Name
          </FormLabel>
          <TextField
            disabled={!editable}
            placeholder="Account Holder Name"
            variant="outlined"
            fullWidth
            value={formData.sellerBankDetails.accountHolderName}
            onChange={(e) =>
              handleBankDetailsChange("accountHolderName", e.target.value)
            }
            name="accountHolderName"
            margin="normal"
          />
        </Grid>
        <Grid item xs={6}>
          <FormLabel disabled={!editable} htmlFor="accountBankIFSC">
            Bank IFSC
          </FormLabel>
          <TextField
            disabled={!editable}
            placeholder="Bank IFSC"
            variant="outlined"
            fullWidth
            value={formData.sellerBankDetails.accountBankIFSC}
            onChange={(e) =>
              handleBankDetailsChange("accountBankIFSC", e.target.value)
            }
            name="accountBankIFSC"
            margin="normal"
          />
        </Grid>
        <Grid item xs={6}>
          <FormLabel disabled={!editable} htmlFor="accountBankName">
            Bank Name
          </FormLabel>
          <TextField
            disabled={!editable}
            placeholder="Bank Name"
            variant="outlined"
            fullWidth
            value={formData.sellerBankDetails.accountBankName}
            onChange={(e) =>
              handleBankDetailsChange("accountBankName", e.target.value)
            }
            name="accountBankName"
            margin="normal"
          />
        </Grid>

        <Grid item xs={12}>
          <ImageUploader
            downloadable
            label="Bank Proof"
            imageUrl={formData.sellerBankDetails.bankProof}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant={"h5"}>GST Details</Typography>
        </Grid>
        <Grid item xs={12}>
          <FormLabel disabled={!editable} htmlFor="gstNumber">
            GST Number
          </FormLabel>
          <TextField
            disabled={!editable}
            placeholder="GST Number"
            variant="outlined"
            fullWidth
            value={formData.sellerLicenseDetails.gstDetails.gstNumber}
            onChange={(e) =>
              handleGstDetailsChange("gstNumber", e.target.value)
            }
            name="gstNumber"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          <ImageUploader
            downloadable
            label="GST Certificate"
            imageUrl={formData.sellerLicenseDetails.gstDetails.gstCertificate}
          />
        </Grid>
        {/* PAN Details */}
        <Grid item xs={12}>
          <Typography variant={"h5"}>PAN Details</Typography>
        </Grid>
        <Grid item xs={12}>
          <FormLabel disabled={!editable} htmlFor="name">
            Name
          </FormLabel>
          <TextField
            disabled={!editable}
            placeholder="Name"
            variant="outlined"
            fullWidth
            value={formData.sellerLicenseDetails.panDetails.name}
            onChange={(e) => handlePanDetailsChange("name", e.target.value)}
            name="name"
            margin="normal"
          />
        </Grid>
        <Grid item xs={6}>
          <FormLabel disabled={!editable} htmlFor="address">
            Address
          </FormLabel>
          <TextField
            disabled={!editable}
            placeholder="Address"
            variant="outlined"
            fullWidth
            value={formData.sellerLicenseDetails.panDetails.address}
            onChange={(e) => handlePanDetailsChange("address", e.target.value)}
            name="address"
            margin="normal"
          />
        </Grid>
        <Grid item xs={6}>
          <FormLabel disabled={!editable} htmlFor="panNumber">
            PAN Number
          </FormLabel>
          <TextField
            disabled={!editable}
            placeholder="PAN Number"
            variant="outlined"
            fullWidth
            value={formData.sellerLicenseDetails.panDetails.panNumber}
            onChange={(e) =>
              handlePanDetailsChange("panNumber", e.target.value)
            }
            name="panNumber"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          <ImageUploader
            downloadable
            label="Pan Card Proof"
            imageUrl={formData.sellerLicenseDetails.panDetails.panCardImage}
          />
        </Grid>
        {/* FSSAI Details */}
        <Grid item xs={12}>
          <Typography variant={"h5"}>FSSAI Details</Typography>
        </Grid>
        <Grid item xs={6}>
          <FormLabel disabled={!editable} htmlFor="fssaiNumber">
            FSSAI Number
          </FormLabel>
          <TextField
            disabled={!editable}
            placeholder="FSSAI Number"
            variant="outlined"
            fullWidth
            value={formData.sellerLicenseDetails.fssaiDetails.fssaiNumber}
            onChange={(e) =>
              handleFssaiDetailsChange("fssaiNumber", e.target.value)
            }
            name="fssaiNumber"
            margin="normal"
          />
        </Grid>
        <Grid item xs={6}>
          <FormLabel disabled={!editable} htmlFor="expiry">
            Expiry Date
          </FormLabel>
          <TextField
            disabled={!editable}
            placeholder="Expiry Date"
            variant="outlined"
            fullWidth
            value={formData.sellerLicenseDetails.fssaiDetails.expiry}
            onChange={(e) => handleFssaiDetailsChange("expiry", e.target.value)}
            name="expiry"
            margin="normal"
          />
        </Grid>

        <Grid item xs={12}>
          <ImageUploader
            downloadable
            label="FSSAI Proof"
            imageUrl={formData.sellerLicenseDetails.fssaiDetails.fssaiImages}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            disabled={!editable}
            control={
              <Checkbox
                checked={formData.isVerified}
                onChange={(e, c) =>
                  setFormData((prevData) => ({ ...prevData, isVerified: c }))
                }
                name="isVerified"
              />
            }
            label="Is Verified"
          />
        </Grid>
      </Grid>
      <Button
        disabled={!editable}
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleSubmit}
      >
        Submit
      </Button>
      {/* Add more fields as needed */}
    </Stack>
  );
};

export default RestaurantForm;
