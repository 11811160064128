import { useState, useEffect } from "react";
import {
  Breadcrumbs,
  Link,
  Stack,
  Button,
  Divider,
} from "@mui/material";
import {
  Outlet,
  Link as RouterLink,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { ChevronLeft } from "@mui/icons-material";
import { Driver, SellerAllData } from "../interfaces";
import { restService } from "../services/rest.service";
import RestaurantImageUploader from "../components/RestaurantImageUploader";
import { trackPromise } from "react-promise-tracker";

const DriverPage = () => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [driver, setDriver] = useState<Driver | null>(null);
  useEffect(() => {
    if (id) {
      trackPromise(restService
        .getDriver(parseInt(id)))
        .then((res) => {
          setDriver(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);
  if(!driver)return <></>
  return (
    <Stack spacing={2}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Breadcrumbs>
          <Link
            underline="hover"
            color="inherit"
            component={RouterLink}
            sx={{ display: "flex", alignItems: "center" }}
            to={"/admin/drivers"}
          >
            <ChevronLeft sx={{ mr: 0.5 }} fontSize="inherit" />
            Drivers
          </Link>
        </Breadcrumbs>
      </Stack>
      {/* <RestaurantImageUploader restaurantData={driver} /> */}
      <Stack direction={"row"} spacing={2}>
        <Button
          size="large"
          onClick={() => navigate(`/admin/driver/${driver?.id}`)}
          variant={
            pathname === `/admin/driver/${driver.id}` ? "contained" : "outlined"
          }
        >
          Driver Details
        </Button>
        <Button
          size="large"
          onClick={() => navigate(`/admin/driver/${driver.id}/menu`)}
          variant={
            pathname === `/admin/driver/${driver.id}/menu`
              ? "contained"
              : "outlined"
          }
        >
          License Details
        </Button>
      </Stack>
      <Divider />
      <Outlet
        context={
          {
            driver,
          } satisfies {
            driver: Driver;
          }
        }
      />
    </Stack>
  );
};

export default DriverPage;
export function useDriver() {
  return useOutletContext<{
    driver: Driver;
  }>();
}
