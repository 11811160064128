import { Stack } from "@mui/material";
import React from "react";
import { icons } from "../assets";

const ApplicationLoading = () => {
  return (
    <Stack justifyContent={"center"} alignItems={"center"}>
      <img
        src={icons.logo_blue}
        alt="hungrezy-logo"
        width={"30%"}
        height={"30%"}
      />
    </Stack>
  );
};

export default ApplicationLoading;
