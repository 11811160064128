// RestaurantVerification.tsx
import React, { useState } from "react";
import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import DocumentCard from "../components/VerificationDocumentCard";
import MenuCard from "../components/VerificationMenuCard";

type SectionKey = "documents" | "menu";

interface Section {
  title: string;
  details: string[][];
}

const DocumentSections: Section[] = [
  {
    title: "Owner Details",
    details: [
      ["Owner Name", "Manan"],
      ["Mobile Number", "131231414"],
      ["Email", "test@test.com"],
      ["Instagram Link", "test.com/ig"],
    ],
  },
  {
    title: "Restaurant Details",
    details: [
      ["Restaurant Name", "Manan Dhaba"],
      ["Restaurant Address", "#987 Sector 14C"],
      ["Establishment Type", "Deliver/Dine-in"],
      ["Best choices", "Indian/Chinese"],
      ["Restaurant Operational Hours", "9:00AM-10:00PM"],
      ["Restaurant Images", "image"],
    ],
  },
  {
    title: "Documents",
    details: [
      ["PAN Holder Name", "Manan Jindal"],
      ["PAN Number", "54*******"],
      ["PAN Photo (front/back)", "image"],
    ],
  },
  {
    title: "GST",
    details: [
      ["GST Information", "12****"],
      ["GST Certificate", "12****"],
    ],
  },
  {
    title: "FSSAI",
    details: [
      ["FSSAI Certificate", "image"],
      ["FSSAI Number of Legal Entry", "12****"],
      ["FSSAI Expiry Date", "12****"],
    ],
  },
  {
    title: "Bank",
    details: [
      ["Bank Details", "12****"],
      ["Bank Account Number", "12****"],
      ["Account Type", "12****"],
      ["IFSC Code", "12****"],
    ],
  },
];

const MenuSections: Section[] = [
  {
    title: "Starters",
    details: [
      ["Name", "Paneer Tikka"],
      ["Price", "Rs. 260"],
      [
        "Description",
        "Combine onion, bell pepper, salad greens, deli meat, and tomato in a large salad bowl. Sprinkle with onion powder, garlic powder, salt, and black pepper; toss well to mix.",
      ],
    ],
  },
  {
    title: "Main Course",
    details: [
      ["Name", "Daal Makhni"],
      ["Price", "Rs. 450"],
      [
        "Description",
        "Combine onion, bell pepper, salad greens, deli meat, and tomato in a large salad bowl. Sprinkle with onion powder, garlic powder, salt, and black pepper; toss well to mix.",
      ],
    ],
  },
];

const RestaurantVerification: React.FC = () => {
  const [dataType, setDataType] = useState<SectionKey>("documents");

  const sections: Record<SectionKey, Section[]> = {
    documents: DocumentSections,
    menu: MenuSections,
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
    >
      <img src={require("./../assets/logo.png")} alt="Logo" width={300} />
      <Typography fontSize="32px" className="playfair-font" mb={2}>
        Dear Manan <br />
        Please verify your Restaurant details...
      </Typography>

      <ToggleButtonGroup
        value={dataType}
        exclusive
        onChange={(e, value) => value && setDataType(value)}
        aria-label="data type"
        sx={{ display: "flex" }}
      >
        <ToggleButton value="documents" sx={{ borderRadius: "30px" }}>
          Documents
        </ToggleButton>
        <ToggleButton value="menu" sx={{ borderRadius: "30px" }}>
          Menu
        </ToggleButton>
      </ToggleButtonGroup>
      {sections[dataType].map(({ title, details }) => (
        <Box key={title} mb={2}>
          {dataType === "documents" ? (
            <DocumentCard title={title} details={details} />
          ) : (
            <MenuCard title={title} details={details} />
          )}
        </Box>
      ))}
    </Box>
  );
};

export default RestaurantVerification;
