import {
  Alert,
  AlertColor,
  Button,
  DialogActions,
  DialogTitle,
  TextField,
} from "@mui/material";
import { async } from "q";
import React, { useState } from "react";
import { restService } from "../services/rest.service";
import { trackPromise } from "react-promise-tracker";

interface SignUpFormProps {
  handleClose: () => void;
}

const SignUpForm: React.FC<SignUpFormProps> = ({ handleClose }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [secretCode, setSecretCode] = useState("");
  const [errorSignIn, setErrorSignIn] = useState(false);
  const [alert, setAlert] = useState<{
    severity: AlertColor | undefined;
    message: string;
  }>({
    severity: undefined,
    message: "",
  });

  const submitHandler = async (event: any) => {
    event.preventDefault();
    try {
      const response = await trackPromise(restService.signupApi(
        name,
        email,
        password,
        secretCode
      ));
      setAlert({
        severity: "success",
        message: response.data.message,
      });
      handleClose();
    } catch (error: any) {
      console.log(error);
      setAlert({
        severity: "error",
        message: error.response.data.message,
      });
    }
  };

  const validation = (email: string, password: string, secretCode: string) => {
    // Email and Password Validation - todo
    return (
      email.includes("@") && password.length > 6 && secretCode.includes("#")
    );
  };

  const nameHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const inputName = event.target.value;
    setName(inputName);
  };
  const emailHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const inputEmail = event.target.value;
    setErrorSignIn(validation(inputEmail, password, secretCode));
    setEmail(inputEmail);
  };

  const passwordHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const inputPassword = event.target.value;
    setErrorSignIn(validation(email, inputPassword, secretCode));
    setPassword(inputPassword);
  };

  const secretCodeHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const inputSecretCode = event.target.value;
    setErrorSignIn(validation(email, password, inputSecretCode));
    setSecretCode(inputSecretCode);
  };

  return (
    <form onSubmit={submitHandler}>
      <DialogTitle>Sign Up</DialogTitle>
      <TextField
        margin="normal"
        label="Enter Full Name"
        type="text"
        fullWidth
        value={name}
        onChange={nameHandler}
      />
      <TextField
        margin="normal"
        label="Enter Email address"
        type="email"
        fullWidth
        value={email}
        onChange={emailHandler}
      />
      <TextField
        margin="normal"
        label="Enter Password"
        type="password"
        fullWidth
        value={password}
        onChange={passwordHandler}
      />
      <TextField
        margin="normal"
        label="Secret Code"
        type="password"
        fullWidth
        value={secretCode}
        onChange={secretCodeHandler}
      />
      <DialogActions sx={{ paddingX: 0 }}>
        <Button
          type="submit"
          fullWidth
          disabled={!errorSignIn}
          variant="contained"
        >
          Sign Up
        </Button>
      </DialogActions>
      {alert.severity && (
        <Alert severity={alert.severity}>{alert.message}</Alert>
      )}
    </form>
  );
};

export default SignUpForm;
