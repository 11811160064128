// sellerSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SellerAllData, SellerData, SellersApiResponse } from "../interfaces";
import { stat } from "fs";

export interface SellerState {
  order: "asc" | "desc";
  orderBy: keyof SellerData;
  selected: number[];
  page: number;
  rowsPerPage: number;
  sellersData: SellerData[];
  total: number;
}

const initialState: SellerState = {
  order: "asc",
  orderBy: "createdAt",
  selected: [],
  page: 1,
  rowsPerPage: 10,
  sellersData: [],
  total: 0,
};

const sellerSlice = createSlice({
  name: "seller",
  initialState,
  reducers: {
    setOrder: (state, action: PayloadAction<"asc" | "desc">) => {
      state.order = action.payload;
    },
    setOrderBy: (state, action: PayloadAction<keyof SellerData>) => {
      state.orderBy = action.payload;
    },
    setSelected: (state, action: PayloadAction<number[]>) => {
      state.selected = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action: PayloadAction<number>) => {
      state.page = 1;
      state.rowsPerPage = action.payload;
    },
    setSellerData: (state, action: PayloadAction<SellersApiResponse>) => {
      const { sellers, total } = action.payload;
      const sellerData = sellers.map(
        (el) =>
          ({
            createdAt: el.createdAt,
            documents:
              el.sellerBankDetails && el.sellerLicenseDetails ? true : false,
            id: el.id,
            isVerified: el.isVerified,
            restaurantName: el.restaurantName,
            menu: el.menuImage ? true : false,
          } as SellerData)
      );
      state.total = total;
      state.sellersData = sellerData;
    },
    updateSellerData: (state, action: PayloadAction<SellerData[]>) => {
      state.sellersData = action.payload;
    },
    deleteSeller: (state, action: PayloadAction<number>) => {
      const newSellerDate = state.sellersData.filter(
        (el) => el.id !== action.payload
      );
      state.sellersData = newSellerDate;
      state.total = state.total - 1;
    },
  },
});

export const {
  setOrder,
  setOrderBy,
  setSelected,
  setPage,
  setRowsPerPage,
  setSellerData,
  deleteSeller,
  updateSellerData,
} = sellerSlice.actions;

export default sellerSlice.reducer;
