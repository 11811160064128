import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PromoCard from "../components/PromoCard";
import SearchIcon from "@mui/icons-material/Search";
import PromoForm from "../components/PromoForm";
import { Promo } from "../interfaces";
import { deletePromo } from "../store/promo.slice";

const Promotions = () => {
  const [searchText, setSearchText] = useState<string>("");
  const [openDialog, setOpenDialog] = useState(false);
  const [promoData, setPromoData] = useState<Promo[]>([]);
  const [updatePromo, setUpdatePromo] = useState<Promo | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    // Simulating API call
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const searchHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const openDialogHandler = () => {
    setOpenDialog(true);
    setUpdatePromo(null);
  };

  const closeDialogHandler = () => {
    setOpenDialog(false);
  };

  const changePromoHandler = (newPromo: Promo) => {
    const promoIndex = promoData.findIndex((promo) => promo.id === newPromo.id);
    const updatedPromoData = [...promoData];

    if (promoIndex !== -1) {
      updatedPromoData[promoIndex] = newPromo; // Update existing promo
    } else {
      updatedPromoData.push(newPromo); // Add new promo
    }
    setPromoData(updatedPromoData);
    setOpenDialog(false);
  };

  const updatePromoHandler = (promo: Promo) => {
    setUpdatePromo(promo);
    setOpenDialog(true);
  };

  const deletePromoHandler = (id: number) => {
    deletePromo(id);
    const updatedPromoData = promoData.filter((promo) => promo.id !== id);
    setPromoData(updatedPromoData);
  };
  const filteredPromos = promoData.filter((promo) =>
    promo.promoCode.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <Box>
      <Stack spacing={2}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography variant="h6" fontWeight={"bold"}>
            Promo Code
          </Typography>
        </Stack>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "space-between",
            gap: 2,
            alignItems: "center",
          }}
        >
          <TextField
            placeholder="Search by Code"
            onChange={searchHandler}
            InputProps={{
              startAdornment: <SearchIcon />,
            }}
          />
          <Button variant="contained" size="large" onClick={openDialogHandler}>
            + Promo Code
          </Button>
          <PromoForm
            open={openDialog}
            handleClose={closeDialogHandler}
            changePromoHandler={changePromoHandler}
            promoToUpdate={updatePromo}
          />
        </Paper>
        {searchText === "" ? (
          <PromoCard
            promoData={filteredPromos}
            openUpdateDialog={updatePromoHandler}
            deletePromo={deletePromoHandler}
          />
        ) : filteredPromos.length > 0 ? (
          <PromoCard
            promoData={filteredPromos}
            openUpdateDialog={updatePromoHandler}
            deletePromo={deletePromoHandler}
          />
        ) : (
          <p>No results found</p>
        )}
      </Stack>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default Promotions;
