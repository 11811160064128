import React from "react";
import {
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Box,
  Paper,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const FAQScreen = () => {
  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Frequently Asked Questions
      </Typography>
      {generateFAQItems()}
    </Container>
  );
};

const generateFAQItems = () => {
  const faqItems = [
    {
      question: "What is Hungrezy Customer Care Number",
      answer:
        "We value our customer’s time and hence moved away from a single customer care number to a comprehensive chat-based support system for quick and easy resolution. You no longer have to go through the maze of an IVRS call support. Just search for your issue in the help section on this page and initiate a chat with us. A customer care executive will be assigned to you shortly. You can also email us your issue on support@hungrezy.in. Note: We value your privacy and your information is safe with us. Please do not reveal any personal information, bank account number, OTP etc. to another person. A Hungrezy representative will never ask you for these details. Please do not reveal these details to fraudsters and imposters claiming to be calling on our behalf. Be vigilant and do not entertain phishing calls or emails.",
    },
    {
      question: "I want to cancel my order",
      answer:
        "We will do our best to accommodate your request if the order is not placed to the restaurant (Customer service number: ). Please note that we will have a right to charge a cancellation fee up to full order value to compensate our restaurant and delivery partners if your order has been confirmed.",
    },
    {
      question: "Is there a minimum order value?",
      answer:
        "We have no minimum order value and you can order for any amount.",
    },
  ];

  return (
    <Stack>
      <Stack paddingX={"40px"} gap={4}>
        <Box
          display={"flex"}
          justifyContent={"center"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <img src={require("./../assets/logo.png")} width={300} />
          <Typography
            variant="overline"
            fontSize={"32px"}
            className="playfair-font"
          >
            FAQ
          </Typography>
        </Box>
        <Container maxWidth="md">
          <Paper elevation={3} style={{ padding: "20px" }}>
            {faqItems.map((item, index) => (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                >
                  <Typography variant="h6">{item.question}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{item.answer}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Paper>
        </Container>
      </Stack>
    </Stack>
  );
};

export default FAQScreen;
