import {
  Box,
  Pagination,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TableFilters, { SellerFilters } from "../components/SellerTableFilters";
import { restService } from "../services/rest.service";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import { setPage, setRowsPerPage } from "../store/drivers.slice";
import RowsPerPage from "../components/RowsPerPage";
import { setDriverData } from "../store/drivers.slice";
import DriverDataTable from "../components/DriverDataTable";
import { trackPromise } from "react-promise-tracker";

const Drivers = () => {
  const dispatch = useDispatch();
  const page = useSelector((state: RootState) => state.driver.page);
  const total = useSelector((state: RootState) => state.driver.total);
  const rowsPerPage = useSelector(
    (state: RootState) => state.driver.rowsPerPage
  );
  const [filters, setFilters] = useState<any>({
    verified: false,
    unverified: false,
  }); // Replace 'any[]' with the actual type of your data

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    dispatch(setPage(page));
  };
  const handleRowPerPageChange = (page: number) => {
    dispatch(setRowsPerPage(page));
  };

  function calculateTotalPages(totalRows: number, rowsPerPage: number): number {
    return Math.ceil(totalRows / rowsPerPage);
  }

  function getVerifiedBoolean() {
    if (filters.verified && filters.unverified) {
      return undefined;
    } else if (filters.verified) {
      return true;
    } else if (filters.unverified) {
      return false;
    } else {
      return undefined;
    }
  }

  useEffect(() => {
    trackPromise(restService
      .getAllDrivers(page, rowsPerPage, getVerifiedBoolean()))
      .then((res) => {
        dispatch(setDriverData(res));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [page, rowsPerPage, filters]);
  return (
    <Box>
      <Stack spacing={2}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography variant="h6" fontWeight={"bold"}>
            Drivers
          </Typography>
          <Typography variant="caption">
            Showing {rowsPerPage} of {total} drivers
          </Typography>
        </Stack>

        {/* <TableFilters setFilters={setFilters} value={filters} /> */}
        <Paper
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "space-between",
            gap: 2,
            alignItems: "center",
          }}
        >
          <RowsPerPage value={rowsPerPage} onChange={handleRowPerPageChange} />
          <Pagination
            count={calculateTotalPages(total, rowsPerPage)}
            onChange={handlePageChange}
          />
        </Paper>
        <DriverDataTable />
        <Paper
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "space-between",
            gap: 2,
            alignItems: "center",
          }}
        >
          <RowsPerPage value={rowsPerPage} onChange={handleRowPerPageChange} />
          <Pagination
            count={calculateTotalPages(total, rowsPerPage)}
            onChange={handlePageChange}
          />
        </Paper>
      </Stack>
    </Box>
  );
};

export default Drivers;
