import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Home from "../pages/Home";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsConditions from "../pages/TermsConditions";
import RefundCancellation from "../pages/RefundCancellation";
import HelpScreen from "../pages/HelpScreen";
import FAQScreen from "../pages/FAQScreen";
import Dashboard from "../pages/Dashboard";
import Merchants from "../pages/Merchants";
import PrivateRoute from "./PrivateRoute";
import PublicRoutes from "./PublicRoutes";
import RestaurantPage from "../pages/RestaurantPage";
import RestaurantForm from "../components/RestauranForm";
import MenuPage from "../pages/MenuPage";
import Promotions from "../pages/Promotions";
import Drivers from "../pages/Drivers";
import DriverPage from "../pages/DriverPage";
import Orders from "../pages/Orders";
import RestaurantConfig from "../pages/RestaurantConfig";
import RestaurantVerification from "../pages/RestaurantVerification";
import CreateSeller from "../pages/CreateSeller";
import SellerAddress from "../pages/SellerAddress";
import ContactScreen from "../pages/ContactScreen";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path="/restaurantVerification"
        element={<RestaurantVerification />}
      />
      <Route path="/" element={<PublicRoutes />}>
        <Route index element={<Home />} />
        <Route path="privacypolicy" element={<PrivacyPolicy />} />
        <Route path="termsconditions" element={<TermsConditions />} />
        <Route path="refundCancellation" element={<RefundCancellation />} />
        <Route path="help" element={<HelpScreen />} />
        <Route path="faq" element={<FAQScreen />} />
        <Route path="contact" element={<ContactScreen />} />
      </Route>
      <Route path="/admin" element={<PrivateRoute />}>
        <Route index element={<Dashboard />} />
        <Route path="/admin/restaurants" element={<Merchants />}></Route>

        <Route path="/admin/orders" element={<Orders />} />
        <Route path="/admin/restaurants/:id" element={<RestaurantPage />}>
          <Route index element={<RestaurantForm />} />
          <Route path="/admin/restaurants/:id/menu" element={<MenuPage />} />
          <Route
            path="/admin/restaurants/:id/config"
            element={<RestaurantConfig />}
          />
          <Route
            path="/admin/restaurants/:id/address"
            element={<SellerAddress />}
          />
        </Route>
        <Route path="/admin/drivers" element={<Drivers />} />
        <Route path="/admin/drivers/:id" element={<DriverPage />}></Route>
        <Route path="/admin/promotions" element={<Promotions />} />
        <Route
          path="/admin/restaurants/create/new"
          element={<CreateSeller />}
        />
      </Route>
    </>
  )
);
