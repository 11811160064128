import { useState, useEffect } from "react";
import {
  Breadcrumbs,
  Link,
  Stack,
  Button,
  Divider,
} from "@mui/material";
import {
  Outlet,
  Link as RouterLink,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { ChevronLeft } from "@mui/icons-material";
import { SellerAllData } from "../interfaces";
import { restService } from "../services/rest.service";
import RestaurantImageUploader from "../components/RestaurantImageUploader";
import { trackPromise } from "react-promise-tracker";

const RestaurantPage = () => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [seller, setSeller] = useState<SellerAllData | null>(null);
  useEffect(() => {
    if (id) {
      trackPromise(restService
        .getSeller(parseInt(id)))
        .then((res) => {
          setSeller(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);
  if ( !seller)
    return (
      <>
      </>
    );
  return (
    <Stack spacing={2}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Breadcrumbs>
          <Link
            underline="hover"
            color="inherit"
            component={RouterLink}
            sx={{ display: "flex", alignItems: "center" }}
            to={"/admin/restaurants"}
          >
            <ChevronLeft sx={{ mr: 0.5 }} fontSize="inherit" />
            Restaurants
          </Link>
        </Breadcrumbs>
      </Stack>
      <RestaurantImageUploader restaurantData={seller} />
      <Stack direction={"row"} spacing={2}>
        <Button
          size="large"
          onClick={() => navigate(`/admin/restaurants/${seller.id}`)}
          variant={
            pathname === `/admin/restaurants/${seller.id}`
              ? "contained"
              : "outlined"
          }
        >
          Restaurant Details
        </Button>
        <Button
          size="large"
          onClick={() => navigate(`/admin/restaurants/${seller.id}/menu`)}
          variant={
            pathname === `/admin/restaurants/${seller.id}/menu`
              ? "contained"
              : "outlined"
          }
        >
          Food Menu
        </Button>
        <Button
          size="large"
          onClick={() => navigate(`/admin/restaurants/${seller.id}/address`)}
          variant={
            pathname === `/admin/restaurants/${seller.id}/adress`
              ? "contained"
              : "outlined"
          }
        >
          Restaurant Address
        </Button>
        <Button
          size="large"
          onClick={() => navigate(`/admin/restaurants/${seller.id}/config`)}
          variant={
            pathname === `/admin/restaurants/${seller.id}/config`
              ? "contained"
              : "outlined"
          }
        >
          Restaurant Config
        </Button>
      </Stack>
      <Divider />
      <Outlet
        context={
          {
            seller,
          } satisfies {
            seller: SellerAllData;
          }
        }
      />
    </Stack>
  );
};

export default RestaurantPage;
export function useSeller() {
  return useOutletContext<{
    seller: SellerAllData;
  }>();
}
