import "./App.css";
import { ThemeProvider } from "@mui/material";
import theme from "./theme/theme";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import store, { persistor } from "./store/store";
import ApplicationLoading from "./components/ApplicationLoading";
import { RouterProvider } from "react-router-dom";
import { router } from "./Routes/AllRoutes";
import ScrollTopButton from "./components/BackToTop";
import LoadingIndicator from "./components/LoadingIndicator";

function App() {
  
  return (
    <Provider store={store}>
      <PersistGate loading={<ApplicationLoading />} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <LoadingIndicator/>
          <RouterProvider router={router} />
          <ScrollTopButton />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
