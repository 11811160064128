import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { OrderDetails } from "../interfaces";

export interface orderState {
  orders: OrderDetails[];
}

const initialState: orderState = {
    orders: [],
};
const orderSlice = createSlice({
  name: "orderSlice",
  initialState,
  reducers: {
    setOrders: (state, action: PayloadAction<OrderDetails[]>) => {
      state.orders= action.payload;
    }
  },
});
export const { setOrders} = orderSlice.actions;

export default orderSlice.reducer;
