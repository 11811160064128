import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Promo } from "../interfaces";
import { useDispatch } from "react-redux";
import { addPromo, updatePromo } from "../store/promo.slice";
interface PromoFormProps {
  open: boolean;
  handleClose: () => void;
  changePromoHandler: (Promo: any) => void;
  promoToUpdate?: Promo | null;
}
const PromoForm: React.FC<PromoFormProps> = ({
  open,
  handleClose,
  changePromoHandler,
  promoToUpdate,
}) => {
  const initialData: Promo = {
    id: Date.now(),
    promoCode: "",
    discount: 0,
    expiryDate: "",
    minAmount: 0,
  };
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<Promo>(promoToUpdate || initialData);
  const dispatch = useDispatch();

  useEffect(() => {
    if (promoToUpdate) {
      setFormData(promoToUpdate);
    } else {
      setFormData(initialData);
    }
  }, [promoToUpdate]);

  const isUpdate = !!promoToUpdate;

  const submitHandler = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    if (isUpdate) {
      dispatch(updatePromo(formData));

      setTimeout(() => {
        setLoading(false);
        handleClose();
        changePromoHandler(formData);
      }, 1500);
    } else {
      dispatch(addPromo({ ...formData, id: Date.now() }));

      setTimeout(() => {
        setLoading(false);
        handleClose();
        changePromoHandler({ ...formData, id: Date.now() });
      }, 1500);
    }
  };

  const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs">
      <DialogContent>
        <form onSubmit={submitHandler}>
          <DialogTitle>{isUpdate ? "Update" : "Add"} Promo Code</DialogTitle>
          <TextField
            name="promoCode"
            margin="normal"
            label="Promo Code"
            type="text"
            fullWidth
            value={formData.promoCode}
            onChange={inputHandler}
          />
          <TextField
            name="discount"
            margin="normal"
            label="Discount Percent"
            type="number"
            fullWidth
            value={formData.discount}
            onChange={inputHandler}
          />
          <TextField
            name="minAmount"
            margin="normal"
            label="Minimum Amount"
            type="number"
            fullWidth
            value={formData.minAmount}
            onChange={inputHandler}
          />
          <TextField
            name="expiryDate"
            margin="normal"
            type="date"
            fullWidth
            value={formData.expiryDate}
            onChange={inputHandler}
          />
          <DialogActions sx={{ paddingX: 0 }}>
            <Button type="submit" fullWidth variant="contained">
              {isUpdate ? "Update" : "Add"}
            </Button>
          </DialogActions>

          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default PromoForm;
