import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Promo } from "../interfaces";

export interface promoState {
  promoData: Promo[];
}

const initialState: promoState = {
  promoData: [],
};
const promoSlice = createSlice({
  name: "promoCard",
  initialState,
  reducers: {
    addPromo: (state, action: PayloadAction<Promo>) => {
      state.promoData = [...state.promoData, action.payload];
    },
    updatePromo: (state, action: PayloadAction<Promo>) => {
      const { id } = action.payload;
      const existingPromoIndex = state.promoData.findIndex(
        (promo) => promo.id === id
      );
      if (existingPromoIndex !== -1) {
        state.promoData[existingPromoIndex] = action.payload;
      }
    },
    deletePromo: (state, action: PayloadAction<number>) => {
      const deleteId = action.payload;
      state.promoData = state.promoData.filter(
        (promo) => promo.id !== deleteId
      );
    },
  },
});
export const { addPromo, updatePromo, deletePromo } = promoSlice.actions;

export default promoSlice.reducer;
