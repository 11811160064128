import {
  Button,
  FormLabel,
  Grid,
  InputLabel,
  Stack,
  TextField as MuiTextField,
  Typography,
  Select as MuiSelect,
  styled,
} from "@mui/material";
import React, {
  ChangeEvent,
  ChangeEventHandler,
  useEffect,
  useState,
} from "react";
import { restService } from "../services/rest.service";
import { useSeller } from "./RestaurantPage";
import { SellerCharges } from "../interfaces";
import { trackPromise } from "react-promise-tracker";
const TextField = styled(MuiTextField)({
  backgroundColor: "white",
});
const Select = styled(MuiSelect)({
  backgroundColor: "white",
});

const RestaurantConfig = () => {
  const { seller } = useSeller();
  const [formData, setFormData] = useState<SellerCharges>({
    gst: "",
    deliveryfeesfree: "",
    packaging: "",
  });

  const handleFormDataChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const upadteCharges = async () => {
    trackPromise(restService
      .updateSellerCharges(seller.id, formData))
      .catch((err) => console.log(err))
  };
  useEffect(() => {
    trackPromise(restService
      .getSellerCharges(seller.id))
      .then((res) => setFormData(res.charges))
      .catch((err) => console.log(err))
  }, []);

  return (
    <Stack gap={2}>
      <Typography variant={"h5"}>Restaurant Charges</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormLabel>Packaging Charges</FormLabel>
          <TextField
            placeholder="Enter Packaging Charges"
            name="packaging"
            fullWidth
            type="number"
            onChange={handleFormDataChange}
            margin="normal"
            value={formData.packaging}
          />
        </Grid>
        <Grid item xs={12}>
          <FormLabel>Gst Percentage</FormLabel>
          <TextField
            placeholder="Enter Gst Charges"
            name="gst"
            fullWidth
            type="number"
            margin="normal"
            onChange={handleFormDataChange}
            value={formData.gst}
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          <FormLabel>Free Delivery Upto</FormLabel>
          <TextField
            placeholder="Enter Range in KM's"
            name="deliveryfeesfree"
            fullWidth
            type="number"
            margin="normal"
            onChange={handleFormDataChange}
            value={formData.deliveryfeesfree}
          ></TextField>
        </Grid>
      </Grid>
      <Button
        onClick={upadteCharges}
        sx={{ alignSelf: "self-start" }}
        variant="contained"
      >
        Save
      </Button>
    </Stack>
  );
};

export default RestaurantConfig;
