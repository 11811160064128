import {
  Card,
  CardContent,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
interface Value {
  title: string;
  value: number;
  code: string;
}

interface DashboardCardProps {
  title: string;
  values: Value[];
}

const DashboardCard = ({ title, values }: DashboardCardProps) => {
  return (
    <Paper square sx={{ p: 2 }}>
      <Typography variant="h6">{title}</Typography>
      <Divider></Divider>
      <Grid container spacing={1} mt={1}>
        {values.map((el) => (
          <Grid item xs={6}>
            <Typography
              variant="h5"
              fontWeight={"bold"}
              color={el.code + ".main"}
            >
              {el.value}
            </Typography>
            <Typography
              fontWeight={"bold"}
              color={el.code + ".main"}
              variant="body1"
            >
              {el.title}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

export default DashboardCard;
