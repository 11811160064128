import { Button, Box, Typography, TextField, Stack } from "@mui/material";
import ImageDescriptionRow from "../components/ImageDescriptionRow";
import ImageDescriptionColumn from "../components/ImageDescriptionColumn";
import { FaLocationArrow, FaSearch } from "react-icons/fa";
import DownloadBanner from "../components/DownloadBanner";

function Home() {
  return (
    <Stack>
      <Stack paddingX={"40px"} gap={4}>
        <Box
          display={"flex"}
          justifyContent={"center"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <img src={require("./../assets/logo.png")} width={300} />
          <Typography
            variant="overline"
            fontSize={"32px"}
            className="playfair-font"
          >
            HEALTHY FOOD
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontStyle: "italic",
              fontFamily: "cursive",
              color: "primary.main",
            }}
            className="charlotte-font"
          >
            Choices
          </Typography>
        </Box>
        <Box
          padding={"30px"}
          display={"flex"}
          flexDirection={"column"}
          gap={"20px"}
        >
          <Typography color={"primary"}>
            Enter your location to find local restaurants
          </Typography>
          <Box display={"flex"} gap={"10px"}>
            <TextField
              placeholder="Please Enter the Address you want to search the restaurant"
              InputProps={{
                startAdornment: <FaLocationArrow />,
              }}
              fullWidth
            />
            <Button startIcon={<FaSearch />} variant="contained">
              Search
            </Button>
          </Box>
        </Box>
        <Box>
          <img
            src={require("./../assets/foodimage.png")}
            style={{ width: "100%", marginBottom: "20px" }}
          />
          {/* <Typography
            variant="h3"
            sx={{
              fontStyle: "italic",
              fontFamily: "fantasy",
              color: "primary.main",
              textAlign: "center",
              paddingX: "10px",
            }}
          >
            ''
          </Typography> */}
          <Typography
            variant="h4"
            sx={{
              fontStyle: "italic",
              fontFamily: "cursive",
              color: "grey",
              marginBottom: 10,
              textAlign: "center",
              paddingX: "10px",
            }}
            className="playfair-font"
          >
            "Healthy People Eating Healthy Food Should Never Need to Take An
            Antibiotic"
          </Typography>
        </Box>
        <Box display={"flex"}>
          <ImageDescriptionColumn
            title="Something for Everyone"
            imageSrc={require("./../assets/balanced-diet.png")}
            description="lorem asdas asdasd gffef asdasd grfsdf qwdasdasd"
          />
          <ImageDescriptionColumn
            title="Delivery or Pickup"
            isEven
            imageSrc={require("./../assets/delivery-boy.png")}
            description="lorem asdas asdasd gffef asdasd grfsdf qwdasdasd"
          />
          <ImageDescriptionColumn
            title="Save with Hungrezy +"
            imageSrc={require("./../assets/saving.png")}
            description="lorem asdas asdasd gffef asdasd grfsdf qwdasdasd"
          />
        </Box>
        <Box>
          <Typography
            variant="h3"
            color={"primary"}
            textAlign={"center"}
            fontWeight={"bold"}
            margin={5}
            className="poppins-font"
          >
            Hungrezy is a multi-lingual Project
          </Typography>
          <ImageDescriptionRow
            title="On Time delivery from our Delivery Partners "
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ultricies fermentum magna, eu cursus nisl imperdiet quis. Nam est mi, pellentesque sit amet augue at, vestibulum porttitor massa. Morbi vitae venenatis nisl, a sodales tortor. Integer et ullamcorper ex. Nullam at dapibus erat. Donec et risus pulvinar, "
            imageSrc={require("./../assets/on-time-delivery.png")}
          />
          <ImageDescriptionRow
            title="Enroll your Restaurant with us and get Business Insights"
            reverse
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ultricies fermentum magna, eu cursus nisl imperdiet quis. Nam est mi, pellentesque sit amet augue at, vestibulum porttitor massa. Morbi vitae venenatis nisl, a sodales tortor. Integer et ullamcorper ex. Nullam at dapibus erat. Donec et risus pulvinar, "
            imageSrc={require("./../assets/retaurant-image.png")}
          />
          <ImageDescriptionRow
            title="Order hot and fresh Food Online from our App "
            description={
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ultricies fermentum magna, eu cursus nisl imperdiet quis. Nam est mi, pellentesque sit amet augue at, vestibulum porttitor massa. Morbi vitae venenatis nisl, a sodales tortor. Integer et ullamcorper ex. Nullam at dapibus erat. Donec et risus pulvinar, "
            }
            imageSrc={require("./../assets/order-online.png")}
          />
        </Box>
        <Box>
          <Typography
            variant="h3"
            color={"primary"}
            textAlign={"center"}
            fontWeight={"bold"}
            margin={5}
          >
            How Hungerzy Works?
          </Typography>
          <Box display={"flex"} justifyContent={"space-between"}>
            <ImageDescriptionColumn
              step={1}
              description="Order your favorite food at home"
              imageSrc={require("./../assets/step1.png")}
            />
            <ImageDescriptionColumn
              step={2}
              description="Choose from the best restaurants arround you"
              imageSrc={require("./../assets/step2.png")}
            />
            <ImageDescriptionColumn
              step={3}
              description="Fast Delivery at your doorsteps"
              imageSrc={require("./../assets/step3.png")}
            />
          </Box>
        </Box>
      </Stack>
      <DownloadBanner />
    </Stack>
  );
}

export default Home;
