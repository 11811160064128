import { Stack, Typography } from "@mui/material";
import React from "react";
import SellerLocationForm from "../components/SellerAddressForm";
import { restService } from "../services/rest.service";
import { useParams } from "react-router-dom";

const SellerAddress = () => {
  const { id } = useParams();

  const handleSubmit = (data: any) => {
    if (id) {
      restService.addSellerAddress(String(id), data);
    }
  };
  return (
    <Stack>
      <Typography>Restaurant Address</Typography>
      <SellerLocationForm onSubmit={handleSubmit} />
    </Stack>
  );
};

export default SellerAddress;
