import React from "react";
import { Container, Typography, Paper, Link, Stack, Box } from "@mui/material";

const RefundCancellation = () => {
  return (
    <Stack>
      <Stack paddingX={"40px"} gap={4}>
        <Box
          display={"flex"}
          justifyContent={"center"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <img src={require("./../assets/logo.png")} width={300} />
          <Typography
            variant="overline"
            fontSize={"32px"}
            className="playfair-font"
          >
            REFUND and CANCELLATION
          </Typography>
        </Box>
        <Container maxWidth="sm">
          <Paper elevation={3} style={{ padding: "20px" }}>
            <Typography variant="h5">Cancellation and Refund Policy</Typography>
            <Typography variant="subtitle1">
              Any Capitalized terms used but not defined herein shall have the
              meaning assigned to them under the Terms of Use which govern your
              use of our website www.hungrezy.in (the “Website”) and our
              ‘Hungrezy’ application for mobile and handheld devices (the
              “App”). The Website and the App are jointly referred to as the
              “Platform”.
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Shipping Policy
            </Typography>
            <Typography variant="h6">Delivery Areas</Typography>
            <Typography variant="subtitle2">
              Hungrezy operates within specific delivery areas. Users can check
              whether their location is covered by entering their address during
              the ordering process. We reserve the right to add or remove
              delivery areas at our discretion.
            </Typography>
            <Typography variant="h6">Delivery Times</Typography>
            <Typography variant="subtitle2">
              <ol>
                Delivery times may vary depending on the user's location, order
                volume, and other factors.
              </ol>
              <ol>
                Estimated delivery times will be displayed during the checkout
                process.
              </ol>
              <ol>
                In some cases, delivery times may be affected by weather,
                traffic, or unforeseen circumstances.
              </ol>
            </Typography>
            <Typography variant="h6">Delivery Charges</Typography>
            <Typography variant="subtitle2">
              <ol>
                Delivery charges may apply to orders based on the user's
                location and the total order value.
              </ol>
              <ol>
                Any applicable delivery charges will be clearly displayed during
                the checkout process.
              </ol>
            </Typography>
            <Typography variant="h6">Order Tracking</Typography>
            <Typography variant="subtitle2">
              <ol>
                Users can track the status of their orders using our app.
                Real-time updates will be provided, including order preparation,
                pickup, and delivery.
              </ol>
            </Typography>
            <Typography variant="h6">Delivery Partners</Typography>
            <Typography variant="subtitle2">
              <ol>
                Hungrezy collaborates with third-party delivery partners to
                ensure prompt and efficient deliveries.
              </ol>
              <ol>
                Our delivery partners are responsible for handling and
                delivering orders to users' locations.
              </ol>
            </Typography>
            <Typography variant="h6">Contactless Delivery</Typography>
            <Typography variant="subtitle2">
              <ol>
                In light of current health and safety concerns, Hungrezy offers
                contactless delivery options. Users can request a contactless
                delivery in the app.
              </ol>
            </Typography>
            <Typography variant="h6">Delivery Confirmation</Typography>
            <Typography variant="subtitle2">
              <ol>
                Users are required to provide accurate delivery information,
                including delivery address and contact details.
              </ol>
              <ol>
                We will consider the delivery complete once the order has been
                delivered to the specified address.{" "}
              </ol>
            </Typography>
            <Typography variant="h6">Late Deliveries</Typography>
            <Typography variant="subtitle2">
              <ol>
                While we strive to provide timely deliveries, there may be
                instances of late deliveries.{" "}
              </ol>
              <ol>
                Late deliveries do not qualify for compensation, except for
                cases involving significant delays due to our error.{" "}
              </ol>{" "}
            </Typography>
            <Typography variant="h6">Order Cancellation</Typography>
            <Typography variant="subtitle2">
              <ol>
                Users may cancel their orders before they are prepared and
                handed over to the delivery partner.
              </ol>
              <ol>
                Once the order is in the delivery process, it cannot be
                canceled.
              </ol>{" "}
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Return Policy
            </Typography>
            <Typography variant="h6">
              Food Quality and Order Accuracy
            </Typography>
            <Typography variant="subtitle2">
              <ol>
                Hungrezy is committed to providing high-quality food and
                accurate orders.
              </ol>
              <ol>
                If users encounter issues related to food quality or order
                accuracy, they can contact our customer support team for
                assistance.
              </ol>
            </Typography>
            <Typography variant="h6">Refunds and Returns</Typography>
            <Typography variant="subtitle2">
              <ol>
                Our refund and return policy covers issues related to food
                quality and order accuracy.
              </ol>
              <ol>
                Users can contact our support team to request refunds or
                returns.
              </ol>
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Refund Policy
            </Typography>
            <Typography variant="h6">Refund Eligibility</Typography>
            <Typography variant="subtitle2">
              <ol>
                Users may be eligible for a refund if they experience issues
                with their order, such as food quality problems or incorrect
                items.
              </ol>
              <ol>
                Users should contact our support team to request a refund and
                provide relevant details.
              </ol>
            </Typography>
            <Typography variant="h6">Refund Processing</Typography>
            <Typography variant="subtitle2">
              <ol>
                Refund requests will be reviewed and processed by our team.{" "}
              </ol>
              <ol>
                Refunds will be issued based on the circumstances and may be
                provided in the original payment method.{" "}
              </ol>
            </Typography>
            <Typography variant="h6"> Cancellation Refunds</Typography>
            <Typography variant="subtitle2">
              <ol>
                Users who cancel their orders before the preparation process
                begins may receive a full refund.{" "}
              </ol>
              <ol>
                Once the order is in the preparation or delivery process,
                refunds will be handled according to our cancellation policy.
              </ol>
            </Typography>
            <Typography variant="h6">
              {" "}
              Changes to the Shipping, Returns, and Refunds Policy
            </Typography>
            <Typography variant="subtitle2">
              <ol>
                Hungrezy reserves the right to update or modify this policy at
                any time. Users will be notified of any changes.
              </ol>
            </Typography>
            <Typography variant="h6"> Contact Information</Typography>
            <Typography variant="subtitle2">
              If you have questions or concerns about our Shipping, Returns, and
              Refunds Policy, please contact us at:
              <ol>Email: perniciachd@gmail.com</ol>
            </Typography>
          </Paper>
        </Container>
      </Stack>
    </Stack>
  );
};

export default RefundCancellation;
