export interface SellerData {
  id: number;
  isVerified: boolean;
  restaurantName: string;
  createdAt: string;
  menu: boolean;
  documents: boolean;
}
interface GstDetails {
  gstCharge: boolean;
  gstNumber: string;
  gstCertificate: string;
}

interface PanDetails {
  name: string;
  address: string;
  panNumber: string;
  panCardImage: string;
}

interface FssaiDetails {
  expiry: string; // Use a proper Date type if needed
  fssaiImages: string;
  fssaiNumber: string;
}

interface SellerLicenseDetails {
  gstDetails: GstDetails;
  panDetails: PanDetails;
  fssaiDetails: FssaiDetails;
}

interface SellerBankDetails {
  bankProof: string;
  accountBranch: string;
  accountNumber: string;
  accountBankIFSC: string;
  accountBankName: string;
  accountHolderName: string;
}

interface OperationalHours {
  closingTime: string; // Use a proper Date type if needed
  openingDays: string[];
  openingTime: string; // Use a proper Date type if needed
}

export interface SellerAllData {
  id: number;
  phone: string;
  email: string;
  language: string;
  name: string | null;
  restaurantName: string;
  tagLine: string;
  address: string;
  instaProfile: string;
  sellerLicenseDetails: SellerLicenseDetails;
  sellerBankDetails: SellerBankDetails;
  operationalHours: OperationalHours;
  serveType: "both" | "dineIn" | "delivery";
  bestChoices: string[];
  isVerified: boolean;
  menuImage: string;
  menuItems: MenuItem[];
  restaurantImage: string;
  coverImage: string;
  isActive: boolean;
  onlineStatus: boolean;
  activeDeviceToken: string | null;
  storeType: "food" | "grocery";
  createdAt: string;
  updatedAt: string;
}

export interface SellersApiResponse {
  total: number;
  sellers: SellerAllData[];
}

export interface MenuItemPayload {
  name: string;
  description: string;
  costPrice: number;
  marginPercentage: number;
  mealType: MealType;
  category: string;
  subCategory: string;
  image: string;
}
export interface MenuItem {
  id: number;
  name: string;
  description: string;
  costPrice: number;
  marginPercentage: number;
  mealType: MealType;
  category: string;
  subCategory: string;
  inStock: boolean;
  image: string;
}

export enum MealType {
  VEG = "veg",
  NON_VEG = "non-veg",
}

export interface Driver {
  id: number;
  email: string | null;
  language: string;
  phone: string;
  name: string | null;
  address: string | null;
  driverGeneralVehicleData: any | null; // Replace 'any' with the actual type
  driverVehicleLicenseData: any | null; // Replace 'any' with the actual type
  driverVehicleInsuranceData: any | null; // Replace 'any' with the actual type
  driverBankDetails: any | null; // Replace 'any' with the actual type
  isVerified: boolean;
  profileImage: string | null;
  isActive: boolean;
  activeDeviceToken: string | null;
  onlineStatus: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface DriversResponse {
  drivers: Driver[];
  total: number;
}

export interface Promo {
  id: number;
  promoCode: string;
  discount: number;
  expiryDate: string;
  minAmount: number;
}
export interface chargesResponse {
  message: string;
  charges: SellerCharges;
}
export interface SellerCharges {
  id?: number | string;
  gst: number | string;
  deliveryfeesfree: number | string;
  packaging: number | string;
}
export interface Item {
  menuItem: any;
  quantity: number;
  // Add other properties as needed
}

export interface OrderDetails {
  id: number;
  date: string;
  orderItems: Item[];
  paymentMethod: string;
  deliveryAddress: any;
  price: number;
  billDetails: any;
  createdAt: string;
  orderStatus: string;
  seller: any;
  orderDeliveredAt: string;
}
