import React from "react";
import {
  Container,
  Typography,
  Paper,
  Button,
  List,
  ListItem,
  ListItemText,
  Stack,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";

const HelpScreen = () => {
  const navigate = useNavigate();
  const handleNavigation = (url: string) => {
    // Use navigate to navigate to a different route
    navigate(url);
  };
  return (
    <Stack>
      <Stack paddingX={"40px"} gap={4}>
        <Box
          display={"flex"}
          justifyContent={"center"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <img src={require("./../assets/logo.png")} width={300} />
          <Typography
            variant="overline"
            fontSize={"32px"}
            className="playfair-font"
          >
            Help Center
          </Typography>
        </Box>
        <Container maxWidth="md">
          <Paper elevation={3} style={{ padding: "20px" }}>
            <Typography variant="h4" gutterBottom>
              Help Center
            </Typography>
            <Typography variant="body1" gutterBottom>
              Welcome to our Help Center. Here, you can find answers to common
              questions and get assistance.
            </Typography>

            <Typography variant="h5" gutterBottom>
              Frequently Asked Questions
            </Typography>
            <List>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="faq1-content"
                  id="faq1-header"
                >
                  <Typography variant="body1">
                    How do I create an account?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2">
                    To create an account, click on the "Sign Up" button and
                    follow the on-screen instructions.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="faq2-content"
                  id="faq2-header"
                >
                  <Typography variant="body1">
                    How can I reset my password?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2">
                    To reset your password, go to the "Forgot Password" page and
                    follow the steps to reset your password.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="faq3-content"
                  id="faq3-header"
                >
                  <Typography variant="body1">
                    What are the supported payment methods?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2">
                    We support a variety of payment methods, including credit
                    cards, PayPal, and more.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </List>

            <Typography variant="h5" gutterBottom>
              Contact Support
            </Typography>
            <Typography variant="body1" gutterBottom>
              If you couldn't find the answer to your question, feel free to
              contact our support team.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleNavigation("/contact")}
            >
              Contact Support
            </Button>
          </Paper>
        </Container>
      </Stack>
    </Stack>
  );
};

export default HelpScreen;
