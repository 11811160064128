// src/GooglePlayButton.tsx
import React from "react";
import { Button, Typography } from "@mui/material";
import { FaGooglePlay } from "react-icons/fa";

interface GooglePlayButtonProps {
  onClick?: () => void;
  fullWidth?: boolean;
}

const GooglePlayButton: React.FC<GooglePlayButtonProps> = ({
  fullWidth = false,
  onClick,
}) => {
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={onClick}
      style={{ backgroundColor: "black" }}
      fullWidth={fullWidth}
      startIcon={<FaGooglePlay size={24} />}
    >
      <Typography variant="button" sx={{ fontWeight: "bold" }}>
        Google Play
      </Typography>
    </Button>
  );
};

export default GooglePlayButton;
