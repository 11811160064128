class CommonService {
  extractTimeFromTimestamp(timestamp: string): string {
    const date = new Date(timestamp);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const formattedTime = `${hours}:${minutes}`;

    return formattedTime;
  }
  convertTimeToTimestamp(time: string): string {
    const [hours, minutes] = time.split(":").map(Number);
    const date = new Date();
    date.setHours(hours, minutes, 0, 0);

    const options: Intl.DateTimeFormatOptions = { timeZone: "Asia/Kolkata" };
    const isoString = date.toLocaleString("en-US", options);

    return isoString;
  }
  formatDate(dateString: string): string {
    const dateTime = new Date(dateString);

    const year = dateTime.getFullYear();
    const month = String(dateTime.getMonth() + 1).padStart(2, "0");
    const day = String(dateTime.getDate()).padStart(2, "0");

    const hours = String(dateTime.getHours()).padStart(2, "0");
    const minutes = String(dateTime.getMinutes()).padStart(2, "0");
    const seconds = String(dateTime.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }
  formatDateWithTime(inputDate: string) {
    const date = new Date(inputDate);

    const options: any = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: "UTC", // Ensure the date is treated as UTC
    };

    const formattedDate = date.toLocaleDateString("en-US", options);

    return formattedDate;
  }
}
const commonService = new CommonService();
export { commonService };
