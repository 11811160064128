import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import DeliveryDiningOutlinedIcon from "@mui/icons-material/DeliveryDiningOutlined";
import React from "react";

interface DashboardTileProps {
  icon: any;
  title: string;
  value: number;
  colorCode: any;
}

const DashboardTile = ({
  title,
  value,
  colorCode,
  icon,
}: DashboardTileProps) => {
  return (
    <Paper square sx={{ p: 2 }}>
      <Grid container alignItems={"center"} justifyContent={"center"}>
        <Grid item xs spacing={1}>
          <Typography variant="h6" fontWeight={"bold"} textAlign={"center"}>
            {value}
          </Typography>
          <Typography variant="body1" fontWeight={"bold"} textAlign={"center"}>
            {title}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton size="medium" color={colorCode}>
            {icon}
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default DashboardTile;
