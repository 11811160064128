import { Box, Button, Typography } from "@mui/material";
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";
import GooglePlayButton from "./GooglePlayButton";
import AppStoreButton from "./AppStoreButton";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
const Footer = () => {
  const navigate = useNavigate();
  const handleNavigation = (url: string) => {
    // Use navigate to navigate to a different route
    navigate(url);
  };
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );
  return (
    <Box
      sx={{
        bgcolor: "primary.main",
        padding: "16px",
        display: "flex",
        gap: "20px",
      }}
    >
      <Box
        display={"flex"}
        flexGrow={0.1}
        flexDirection={"column"}
        alignItems={"center"}
        gap={"10px"}
        justifyContent={"center"}
      >
        <img src={require("./../assets/white-logo.png")} width={100} />
        <GooglePlayButton fullWidth />
        <AppStoreButton fullWidth />
        <Box
          display={"flex"}
          justifyContent={"space-evenly"}
          width={"100%"}
          mt={"auto"}
          padding={"10px"}
        >
          <FaFacebook color="white" size={24} />
          <FaGoogle color="white" size={24} />
          <FaApple color="white" size={24} />
        </Box>
      </Box>
      {!isAuthenticated && (
        <Box flexGrow={1}>
          <Box
            display={"flex"}
            gap={"20px"}
            mb={"30px"}
            justifyContent={"space-around"}
            color={"white"}
            mt={"30px"}
          >
            <Box display={"flex"} flexDirection={"column"} gap={"20px"}>
              <Button href="./" color="inherit">
                Discover Hungrezy
              </Button>
              <Button href="./" color="inherit">
                About Us
              </Button>
              <Button href="./" color="inherit">
                Become a Partner
              </Button>
              <Button href="./" color="inherit">
                Ride with us
              </Button>
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={"20px"}>
              <Button onClick={() => handleNavigation("/help")} color="inherit">
                Help
              </Button>
              <Button
                color="inherit"
                onClick={() => handleNavigation("/contact")}
              >
                Contact Us
              </Button>
              <Button onClick={() => handleNavigation("/faq")} color="inherit">
                FAQ
              </Button>
              {/* <Button href="./" color="inherit">
                Terms And Condition
              </Button> */}
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={"20px"}>
              {/* <Button href="./" color="inherit">
                Terms And Condition
              </Button> */}
              <Button
                onClick={() => handleNavigation("/termsconditions")}
                color="inherit"
              >
                Terms & Condition
              </Button>
              <Button
                onClick={() => handleNavigation("/refundCancellation")}
                color="inherit"
              >
                Refund & Cancellation
              </Button>
              <Button
                onClick={() => handleNavigation("/privacypolicy")}
                color="inherit"
              >
                Privacy Policy
              </Button>
            </Box>
          </Box>
          <Box textAlign={"center"} color={"white"}>
            <Typography fontWeight={"bold"}>
              2023 Hungrezy . All Rights Reserved
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Footer;
