// src/AppStoreButton.tsx
import React from "react";
import { Button, Typography } from "@mui/material";
import { FaApple } from "react-icons/fa";

interface AppStoreButtonProps {
  onClick?: () => void;
  fullWidth?: boolean;
}

const AppStoreButton: React.FC<AppStoreButtonProps> = ({
  fullWidth = false,
  onClick,
}) => {
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={onClick}
      style={{ backgroundColor: "black" }}
      fullWidth={fullWidth}
      startIcon={<FaApple size={24} style={{ marginRight: 8 }} />}
    >
      <Typography variant="button" sx={{ fontWeight: "bold" }}>
        App Store
      </Typography>
    </Button>
  );
};

export default AppStoreButton;
