import { useState, useEffect } from "react";
import { useSeller } from "./RestaurantPage";
import {
  Button,
  Stack,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import MenuTable from "../components/MenuTable";
import { MenuItem, MenuItemPayload } from "../interfaces";
import { restService } from "../services/rest.service";
import MenuItemForm from "../components/MenuForm";
import { trackPromise } from "react-promise-tracker";

const MenuPage = () => {
  const { seller } = useSeller();
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const [isAddingNewItem, setAddingNewItem] = useState(false);
  const handleToggleAddingNewItem = () => {
    setAddingNewItem(!isAddingNewItem);
  };
  const handleAddNewItem = (newItem: MenuItemPayload) => {
    trackPromise(restService
      .createMenuItem(newItem, seller.id))
      .then((res) => {
        const data: MenuItem = res.data;
        setMenuItems((prev) => [data, ...prev]);
        setAddingNewItem(false);
      })
      .catch((err) => console.log(err))
  };

  useEffect(() => {
    trackPromise(restService
      .getMenuItems(seller.id))
      .then((res) => setMenuItems(res.data))
      .catch((err) => console.log(err))
  }, [seller.id]);

  return (
    <Stack spacing={2}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="h6">Food Items</Typography>

        <Button variant="outlined" onClick={handleToggleAddingNewItem}>
          Add New Item
        </Button>
      </Stack>
      <MenuTable
        menuItems={menuItems}
        setMenuItems={setMenuItems}
        sellerId={seller.id}
      />

      {/* Add New Item Dialog */}
      <Dialog open={isAddingNewItem} onClose={handleToggleAddingNewItem}>
        <DialogTitle>Add New Item</DialogTitle>
        <DialogContent>
          <MenuItemForm onSubmit={handleAddNewItem} sellerId={seller.id} />
        </DialogContent>
      </Dialog>
    </Stack>
  );
};

export default MenuPage;
