import React, { useEffect } from "react";
import {  Stack, Typography } from "@mui/material";
import { restService } from "../services/rest.service";
import OrdersTable from "../components/OrdersTable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import { setOrders } from "../store/order.slice";

const Orders = () => {
const orders = useSelector((state:RootState)=>state.order.orders)
const dispatch = useDispatch()

  useEffect(() => {
    restService.getAllOrders().then((res) => dispatch(setOrders(res.data)));
  }, []);
  return (
    <Stack spacing={2}>
      <Typography variant="h6">Orders</Typography>
      <OrdersTable rows={orders} />
    </Stack>
  );
};

export default Orders;
