import { Container } from "@mui/material";
import React, { useEffect } from "react";
import { RootState } from "../store/store";
import { useSelector } from "react-redux";
import Header from "../components/Header";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";

const PublicRoutes = () => {
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );

  if (isAuthenticated) {
    return <Navigate to={"/admin"} />;
  }
  return (
    <Container maxWidth="xl" disableGutters>
      <Header />
      <Outlet />
      <Footer />
    </Container>
  );
};

export default PublicRoutes;
