import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { OrderDetails } from "../interfaces";
import {
  Avatar,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  TextField,
  Toolbar,
} from "@mui/material";
import { commonService } from "../services/common.service";
import { restService } from "../services/rest.service";
import { useDispatch } from "react-redux";
import { setOrders } from "../store/order.slice";
import { trackPromise } from "react-promise-tracker";
const statusMapping = {
  delivered: { color: "success", title: "Delivered" },
  orderplaced: { color: "primary", title: "New Order" },
  ready: { color: "warning", title: "Ready" },

  preparing: { color: "warning", title: "Preparing" },

  intransit: { color: "warning", title: "Out For Delivery" },
  rejected:{color:'error',title:'Rejected'}
};

function Row(props: { row: OrderDetails }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [reason,setReason] = React.useState('')
  const [openDialog, setOpenDialog] = React.useState(false);
  const dispatch = useDispatch()

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOrderRejection = () => {
    handleCloseDialog();
    // Make API call with rejection reason
    trackPromise(restService.rejectOrder(row.id, reason).then(()=>{
      restService.getAllOrders().then((res) => dispatch(setOrders(res.data)));
    }));
  };
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell padding="checkbox">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" padding="checkbox">
          {row.id}
        </TableCell>
        <TableCell>
          <Avatar src={row.seller.restaurantImage} />
        </TableCell>
        <TableCell>{row.seller.restaurantName}</TableCell>
        <TableCell>{commonService.formatDateWithTime(row.createdAt)}</TableCell>
        <TableCell>
          <Chip
            label={statusMapping[row.orderStatus as "ready"].title}
            color={statusMapping[row.orderStatus as "ready"].color as "primary"}
          />
        </TableCell>
        <TableCell>₹ {row.billDetails[4].amount}</TableCell>
        <TableCell>
          <Button onClick={handleOpenDialog} disabled={row.orderStatus === "delivered" || row.orderStatus === "rejected" } color="error">
            Reject
          </Button>
        </TableCell>
      </TableRow>
      <TableRow sx={{ bgcolor: (theme) => theme.palette.primary.main + 20 }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Grid container>
                <Grid item xs={3}>
                  <List
                    dense
                    subheader={
                      <ListSubheader
                        sx={{
                          bgcolor: "transparent",
                          fontWeight: "bold",
                        }}
                      >
                        Order Items
                      </ListSubheader>
                    }
                  >
                    {row.orderItems.map((el) => (
                      <ListItem key={el.menuItem.id}>
                        <ListItemAvatar>
                          <Avatar
                            sx={{ width: "30px", height: "30px" }}
                            src={el.menuItem.image || ""}
                          />
                        </ListItemAvatar>
                        <ListItemText>
                          {el.menuItem.name} x {el.quantity}
                        </ListItemText>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
                <Grid item xs={4}>
                  <List
                    dense
                    subheader={
                      <ListSubheader
                        sx={{
                          bgcolor: "transparent",
                          fontWeight: "bold",
                        }}
                      >
                        Delivery Information
                      </ListSubheader>
                    }
                  >
                    <ListItem>
                      <ListItemText>{row.deliveryAddress.name}</ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText>{row.deliveryAddress.phone}</ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText>
                        {row.deliveryAddress.address1}
                      </ListItemText>
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={5}>
                  <List
                    dense
                    subheader={
                      <ListSubheader
                        sx={{
                          bgcolor: "transparent",
                          fontWeight: "bold",
                        }}
                      >
                        Timings
                      </ListSubheader>
                    }
                  >
                    <ListItem>
                      <ListItemText>
                        <b>Order Placed At :</b>{" "}
                        {commonService.formatDateWithTime(row.createdAt)}
                      </ListItemText>
                    </ListItem>
                    {row.orderStatus === "delivered" && (
                      <ListItem>
                        <ListItemText>
                          <b>Order Delivered At :</b>{" "}
                          {commonService.formatDateWithTime(
                            row.orderDeliveredAt
                          )}
                        </ListItemText>
                      </ListItem>
                    )}
                  </List>
                </Grid>
                <Grid item xs={5}>
                  <List
                    dense
                    subheader={
                      <ListSubheader
                        sx={{
                          bgcolor: "transparent",
                          fontWeight: "bold",
                        }}
                      >
                        Bill Summary
                      </ListSubheader>
                    }
                  >
                    {row.billDetails.map((el: any) => (
                      <ListItem>
                        <ListItemText>
                          <strong>{el.title}</strong>
                        </ListItemText>
                        <ListItemText sx={{ textAlign: "right" }}>
                          ₹ {el.amount.toFixed(2)}
                        </ListItemText>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth='xl'>
        <DialogTitle>Enter Reason for Rejection</DialogTitle>
        <DialogContent >
          <TextField
            size="small"
            autoFocus
            margin="dense"
            id="reason"
            label="Reason"
            type="text"
            fullWidth
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            multiline
            minRows={4}
          />
          <Button sx={{mt:'20px'}} disabled={!reason} fullWidth onClick={handleOrderRejection} variant="contained">
            Submit
          </Button>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

function EnhancedTableToolbar() {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    ></Toolbar>
  );
}

export default function OrdersTable({ rows }: { rows: OrderDetails[] }) {
  return (
    <TableContainer component={Paper}>
      <EnhancedTableToolbar />
      <Table aria-label="collapsible table">
        <TableHead sx={{ bgcolor: "primary.main" }}>
          <TableRow>
            <TableCell sx={{ color: "white" }} padding="checkbox" />
            <TableCell sx={{ color: "white" }} padding="checkbox">
              ID
            </TableCell>
            <TableCell sx={{ color: "white" }}>Image</TableCell>
            <TableCell sx={{ color: "white" }}>Restaurant</TableCell>
            <TableCell sx={{ color: "white" }}>Order Date</TableCell>
            <TableCell sx={{ color: "white" }}>Order Status</TableCell>
            <TableCell sx={{ color: "white" }}>Bill Amount</TableCell>
            <TableCell sx={{ color: "white" }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.id} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
