import React from "react";
import {
  Paper,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";

interface TableFiltersProps {
  setFilters: (filters: SellerFilters) => void;
  value: SellerFilters;
}

export interface SellerFilters {
  verified: boolean;
  unverified: boolean;
  documentsUploaded: boolean;
  menuUploaded: boolean;
  rejected: boolean;
}

const SellerTableFilters: React.FC<TableFiltersProps> = ({
  setFilters,
  value,
}) => {
  const handleFilterChange = (filter: keyof SellerFilters) => {
    setFilters({
      ...value,
      [filter]: !value[filter],
    });
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="body1" fontWeight={"bold"} mb={2}>
        Apply Filters
      </Typography>
      <FormGroup row sx={{ gap: 2 }}>
        <FormControlLabel
          componentsProps={{
            typography: {
              fontSize: "14px",
              fontWeight: "500",
            },
          }}
          control={
            <Checkbox
              size="small"
              checked={value.verified}
              onChange={() => handleFilterChange("verified")}
            />
          }
          label="Verified"
        />
        <FormControlLabel
          componentsProps={{
            typography: {
              fontSize: "14px",
              fontWeight: "500",
            },
          }}
          control={
            <Checkbox
              size="small"
              checked={value.unverified}
              onChange={() => handleFilterChange("unverified")}
            />
          }
          label="Unverified"
        />
        <FormControlLabel
          componentsProps={{
            typography: {
              fontSize: "14px",
              fontWeight: "500",
            },
          }}
          control={
            <Checkbox
              size="small"
              checked={value.documentsUploaded}
              onChange={() => handleFilterChange("documentsUploaded")}
            />
          }
          label="Documents Uploaded"
        />
        <FormControlLabel
          componentsProps={{
            typography: {
              fontSize: "14px",
              fontWeight: "500",
            },
          }}
          control={
            <Checkbox
              size="small"
              checked={value.menuUploaded}
              onChange={() => handleFilterChange("menuUploaded")}
            />
          }
          label="Menu Uploaded"
        />
        <FormControlLabel
          componentsProps={{
            typography: {
              fontSize: "14px",
              fontWeight: "500",
            },
          }}
          control={
            <Checkbox
              size="small"
              checked={value.rejected}
              onChange={() => handleFilterChange("rejected")}
            />
          }
          label="Rejected"
        />
      </FormGroup>
    </Paper>
  );
};

export default SellerTableFilters;
