import { MenuItem } from "@mui/base";
import { IconButton, Select, Stack, Typography } from "@mui/material";
import React from "react";

const RowsPerPage = ({
  value,
  onChange,
}: {
  value: number;
  onChange: (value: number) => void;
}) => {
  return (
    <Stack direction={"row"} alignItems={"center"} gap={1}>
      <Typography
        sx={{
          fontSize: "0.875rem",
          fontWeight: 400,
          lineHeight: "1.43",
          letterSpacing: "0.01071em",
          color: "rgba(0, 0, 0, 0.87)",
        }}
      >
        Count Per Page
      </Typography>
      {Array.from({ length: 4 }).map((_, index) => (
        <IconButton
          key={index}
          size="medium"
          onClick={() => onChange((index + 1) * 10)}
          color={value / 10 - 1 === index ? "primary" : "default"}
          sx={{
            height: "32px",
            minWidth: "32px",
            fontSize: "0.875rem",
            fontWeight: 400,
            lineHeight: "1.43",
            letterSpacing: "0.01071em",
            color: "rgba(0, 0, 0, 0.87)",
            background:
              value / 10 - 1 === index ? "rgba(0, 0, 0, 0.08)" : "inherit",
          }}
        >
          {(index + 1) * 10}
        </IconButton>
      ))}
    </Stack>
  );
};

export default RowsPerPage;
