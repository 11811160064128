import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Switch,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { restService } from "../services/rest.service";
import { MenuItem } from "../interfaces";
import { Edit } from "@mui/icons-material";
import MenuItemForm from "./MenuForm";
import { trackPromise } from "react-promise-tracker";

interface Props {
  menuItems: MenuItem[];
  setMenuItems: React.Dispatch<React.SetStateAction<MenuItem[]>>;
  sellerId: number;
}

const MenuTable: React.FC<Props> = ({ menuItems, setMenuItems, sellerId }) => {
  const [isUpdateItem, setIsUpdateItem] = useState(false);
  const [selectedItem, setSelectedItem] = useState<MenuItem | null>(null);
  const handleStockChange = async (id: number, value: boolean) => {
    try {
      setMenuItems((prev) =>
        prev.map((item) =>
          item.id === id ? { ...item, inStock: value } : item
        )
      );
      await trackPromise(restService.updateMenuItem({ inStock: value, id }, "food"));
    } catch (error) {
      setMenuItems((prev) =>
        prev.map((item) =>
          item.id === id ? { ...item, inStock: !value } : item
        )
      );
    }
  };

  const handleUpdateItem = async (menuItem: MenuItem) => {
    try {
      const response = await trackPromise(restService.updateMenuItem(menuItem, "food"));
      const updatedItem: MenuItem = response.data;
      setMenuItems((prev) =>
        prev.map((item) => (item.id === menuItem.id ? updatedItem : item))
      );
    } catch (error) {
      console.log(error);
    } finally {
      setIsUpdateItem(false);
      setSelectedItem(null);
    }
  };

  const handleToggleUpdateItem = (item: MenuItem) => {
    setSelectedItem(item);
    setIsUpdateItem(!isUpdateItem);
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Image</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Cost Price</TableCell>
            <TableCell>Margin(%)</TableCell>
            <TableCell>Selling Price</TableCell>
            <TableCell>Meal Type</TableCell>
            <TableCell>Category</TableCell>
            <TableCell>Subcategory</TableCell>
            <TableCell>In Stock</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {menuItems.map((item) => (
            <TableRow key={item.id}>
              <TableCell>
                <Avatar src={item.image} />
              </TableCell>
              <TableCell>{item.name}</TableCell>
              <TableCell>{item.description}</TableCell>
              <TableCell>{item.costPrice}</TableCell>
              <TableCell>{item.marginPercentage}</TableCell>
              <TableCell>
                {+item.costPrice +
                  (+item.costPrice * +item.marginPercentage) / 100}
              </TableCell>
              <TableCell>{item.mealType}</TableCell>
              <TableCell>{item.category}</TableCell>
              <TableCell>{item.subCategory}</TableCell>
              <TableCell>
                <Switch
                  checked={item.inStock}
                  onChange={(e, checked) => handleStockChange(item.id, checked)}
                />
              </TableCell>
              <TableCell>
                <IconButton onClick={() => handleToggleUpdateItem(item)}>
                  <Edit />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          {menuItems.length === 0 && (
            <TableRow>
              <TableCell sx={{ textAlign: "center" }} colSpan={12}>
                No Menu Items
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Dialog open={isUpdateItem} onClose={handleToggleUpdateItem}>
        <DialogTitle>Update Item</DialogTitle>
        <DialogContent>
          {selectedItem && (
            <MenuItemForm
              onSubmit={handleUpdateItem}
              initialData={selectedItem}
              sellerId={sellerId}
            />
          )}
        </DialogContent>
      </Dialog>
    </TableContainer>
  );
};

export default MenuTable;
