import {
  Box,
  Button,
  Pagination,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DataTable from "../components/SellerDataTable";
import TableFilters, { SellerFilters } from "../components/SellerTableFilters";
import { restService } from "../services/rest.service";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import { setPage, setRowsPerPage, setSellerData } from "../store/sellers.slice";
import RowsPerPage from "../components/RowsPerPage";
import { useNavigate } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";

const Merchants = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const page = useSelector((state: RootState) => state.seller.page);
  const total = useSelector((state: RootState) => state.seller.total);
  const rowsPerPage = useSelector(
    (state: RootState) => state.seller.rowsPerPage
  );
  const [filters, setFilters] = useState<SellerFilters>({
    verified: false,
    unverified: false,
    documentsUploaded: false,
    menuUploaded: false,
    rejected: false,
  }); // Replace 'any[]' with the actual type of your data

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    dispatch(setPage(page));
  };
  const handleRowPerPageChange = (page: number) => {
    dispatch(setRowsPerPage(page));
  };

  function calculateTotalPages(totalRows: number, rowsPerPage: number): number {
    return Math.ceil(totalRows / rowsPerPage);
  }

  function getVerifiedBoolean() {
    if (filters.verified && filters.unverified) {
      return undefined;
    } else if (filters.verified) {
      return true;
    } else if (filters.unverified) {
      return false;
    } else {
      return undefined;
    }
  }

  useEffect(() => {
    trackPromise(restService
      .getAllSellers(
        page,
        rowsPerPage,
        getVerifiedBoolean(),
        filters.documentsUploaded,
        filters.menuUploaded
      ))
      .then((res) => {
        dispatch(setSellerData(res));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [page, rowsPerPage, filters]);
  return (
    <Box>
      <Stack spacing={2}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography variant="h6" fontWeight={"bold"}>
            Restaurants
          </Typography>
          <Typography variant="caption">
            Showing {rowsPerPage} of {total} restaurants
          </Typography>
        </Stack>

        <TableFilters setFilters={setFilters} value={filters} />
        <Paper
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "space-between",
            gap: 2,
            alignItems: "center",
          }}
        >
          <RowsPerPage value={rowsPerPage} onChange={handleRowPerPageChange} />
          <Pagination
            count={calculateTotalPages(total, rowsPerPage)}
            onChange={handlePageChange}
          />
        </Paper>
        <Stack justifyContent={"end"} alignItems={"end"}>
          <Button onClick={() => navigation("create/new")} variant="contained">
            Create New Restaurant
          </Button>
        </Stack>
        <DataTable />
        <Paper
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "space-between",
            gap: 2,
            alignItems: "center",
          }}
        >
          <RowsPerPage value={rowsPerPage} onChange={handleRowPerPageChange} />
          <Pagination
            count={calculateTotalPages(total, rowsPerPage)}
            onChange={handlePageChange}
          />
        </Paper>
      </Stack>
    </Box>
  );
};

export default Merchants;
