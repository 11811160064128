import {
  Alert,
  Button,
  Checkbox,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  TextField,
  AlertColor,
} from "@mui/material";
import React, { useState } from "react";
import { restService } from "../services/rest.service";
import { useDispatch } from "react-redux";
import { login } from "../store/auth.slice";
import { trackPromise } from "react-promise-tracker";

interface LoginFormProps {
  handleClose: () => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ handleClose }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [signIn, setSignIn] = useState(false);
  const [errorSignIn, setErrorSignIn] = useState(false);
  const [alert, setAlert] = useState<{
    severity: AlertColor | undefined;
    message: string;
  }>({
    severity: undefined,
    message: "",
  });
  const submitHandler = async (event: any) => {
    event.preventDefault();
    try {
      const response = await trackPromise(restService.loginApi(email, password));
      setAlert({
        severity: "success",
        message: response.data.message,
      });
      handleClose();
      dispatch(
        login({
          email: response.data.user.email,
          name: response.data.user.name,
          token: response.data.token,
        })
      );
    } catch (error: any) {
      setAlert({
        severity: "error",
        message: error.response.data.message,
      });
    }
  };

  const validation = (email: string, password: string) => {
    // Email and Password Validation - todo
    return email.includes("@") && password.length > 6;
  };

  const emailHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    //cannot use event: string -> event.target doesn't exist
    const inputEmail = event.target.value;
    setErrorSignIn(validation(inputEmail, password));
    setEmail(inputEmail);
  };

  const passwordHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const inputPassword = event.target.value;
    setErrorSignIn(validation(email, inputPassword));
    setPassword(inputPassword);
  };

  const signInHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setSignIn(checked);
    // Keep signed in - todo
  };

  return (
    <form onSubmit={submitHandler}>
      <DialogTitle>Login</DialogTitle>
      <TextField
        margin="normal"
        label="Enter Email address"
        type="email"
        fullWidth
        value={email}
        onChange={emailHandler}
      />
      <TextField
        margin="normal"
        label="Enter Password"
        type="password"
        fullWidth
        value={password}
        onChange={passwordHandler}
      />
      <FormControlLabel
        control={<Checkbox checked={signIn} onChange={signInHandler} />}
        label="Keep me signed in"
      />
      <DialogActions sx={{ paddingX: 0 }}>
        <Button
          type="submit"
          fullWidth
          disabled={!errorSignIn}
          variant="contained"
        >
          Sign in
        </Button>
      </DialogActions>
      {alert.severity && (
        <Alert severity={alert.severity}>{alert.message}</Alert>
      )}
    </form>
  );
};

export default LoginForm;
