import {
  Avatar,
  Box,
  IconButton,
  Button,
  Paper,
  Typography,
  LinearProgress,
  CircularProgress,
} from "@mui/material";
import React, { useState, useRef } from "react";
import { Create } from "@mui/icons-material";
import { SellerAllData } from "../interfaces";
import { restService } from "../services/rest.service";

const RestaurantImageUploader = ({
  restaurantData: restData,
}: {
  restaurantData: SellerAllData;
}) => {
  const [loading, setLoading] = useState(false);
  const [restaurantData, setRestaurantData] = useState(restData);
  const [selectedCoverUrl, setSelectedCoverUrl] = useState(
    restaurantData.coverImage || ""
  );
  const [selectedCoverImageData, setSelectedCoverImageData] = useState(null);
  const [selectedProfileUrl, setSelectedProfilUrl] = useState(
    restaurantData.restaurantImage || ""
  );
  const [selectedProfilImageData, setSelectedProfilImageData] = useState(null);
  const coverfileInputRef = useRef<any>(null);
  const profilefileInputRef = useRef<any>(null);

  const handleCoverButtonClick = () => {
    if (coverfileInputRef?.current) {
      coverfileInputRef?.current?.click();
    }
    setSelectedCoverUrl(restaurantData.coverImage || "");
    setSelectedCoverImageData(null);
  };
  const handleCoverProfileClick = () => {
    if (profilefileInputRef?.current) {
      profilefileInputRef?.current?.click();
    }
    setSelectedProfilUrl(restaurantData.restaurantImage || "");
    setSelectedProfilImageData(null);
  };

  const handleCoverFileChange = (event: any) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const image = URL.createObjectURL(selectedFile);
      setSelectedCoverUrl(image);
      setSelectedCoverImageData(selectedFile);
    }
  };
  const handleCProfileFileChange = (event: any) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const image = URL.createObjectURL(selectedFile);
      setSelectedProfilUrl(image);
      setSelectedProfilImageData(selectedFile);
    }
  };

  const handleUploadImages = async () => {
    try {
      setLoading(true);
      let cover = "";
      let profile = "";
      if (selectedCoverImageData) {
        const imagesResp = await restService.uploadImage(
          selectedCoverImageData,
          restaurantData.id
        );
        cover = imagesResp.data.url;
      }
      if (selectedProfilImageData) {
        const imagesResp = await restService.uploadImage(
          selectedProfilImageData,
          restaurantData.id
        );
        profile = imagesResp.data.url;
      }
      const seller = await restService.updateSeller({
        phone: restaurantData.phone,
        coverImage: cover || restaurantData.coverImage,
        restaurantImage: profile || restaurantData.restaurantImage,
      });
      setRestaurantData(seller.data);
      setSelectedCoverImageData(null);
      setSelectedProfilImageData(null);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <Box position={"relative"}>
      <Paper
        sx={{
          position: "relative",
          height: "200px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mb: 10,
        }}
      >
        {loading && (
          <LinearProgress
            sx={{
              position: "absolute",
              zIndex: 10,
              bottom: 0,
              height: "5px",
              width: "100%",
            }}
          />
        )}
        <input
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          ref={coverfileInputRef}
          onChange={handleCoverFileChange}
        />
        <input
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          ref={profilefileInputRef}
          onChange={handleCProfileFileChange}
        />
        <Button
          onClick={handleCoverButtonClick}
          variant="contained"
          sx={{
            position: "absolute",
            right: 10,
            top: 10,
            zIndex: 100,
          }}
          color="inherit"
        >
          Edit Cover Image
        </Button>
        {selectedCoverUrl ? (
          <img
            src={selectedCoverUrl}
            alt="coverImage"
            height={"200px"}
            width={"100%"}
            style={{ objectFit: "cover" }}
          />
        ) : (
          <Typography variant="h6">Upload Cover Image</Typography>
        )}
        <Box
          sx={{
            position: "absolute",
            width: "180px",
            left: 30,
            bottom: -80,
            zIndex: 1000,
          }}
        >
          <Avatar
            sx={{
              width: "180px",
              height: "180px",
              position: "relative",
              backgroundColor: "white",
              opacity: loading ? 0.8 : 1,
            }}
            src={selectedProfileUrl}
          >
            Ab
          </Avatar>
          {loading && (
            <CircularProgress
              size={190}
              thickness={1}
              sx={{
                position: "absolute",
                top: -5,
                left: -5,
                zIndex: 1,
              }}
            />
          )}
          <IconButton
            onClick={handleCoverProfileClick}
            size="small"
            sx={{
              position: "absolute",
              top: 0,
              right: 10,
              zIndex: 1000,
              backgroundColor: "primary.main",
            }}
            color="warning"
          >
            <Create />
          </IconButton>
        </Box>
        {(selectedCoverImageData || selectedProfilImageData) && (
          <Button
            onClick={handleUploadImages}
            disabled={loading}
            variant="outlined"
            sx={{ position: "absolute", right: 0, bottom: -50 }}
          >
            Upload Images
          </Button>
        )}
      </Paper>
    </Box>
  );
};

export default RestaurantImageUploader;
