import React from "react";
import { Stack, Box, Paper, Container, Typography } from "@mui/material";

const ContactScreen = () => {
  return (
    <Stack>
      <Stack paddingX={"40px"} gap={4}>
        <Box
          display={"flex"}
          justifyContent={"center"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <img src={require("./../assets/logo.png")} width={300} />
          <Typography
            variant="overline"
            fontSize={"32px"}
            className="playfair-font"
          >
            Contact Us
          </Typography>
        </Box>
        <Container maxWidth="md">
          <Paper elevation={3} style={{ padding: "20px" }}>
            <Typography variant="h4" gutterBottom>
              Contact Us
            </Typography>
            <Typography variant="body1" gutterBottom>
              In case of any assistance, react out to us at +91-9373-0000-13
            </Typography>
          </Paper>
        </Container>
      </Stack>
    </Stack>
  );
};

export default ContactScreen;
