import React from "react";
import { Container, Typography, Paper, Link, Stack, Box } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <Stack>
      <Stack paddingX={"40px"} gap={4}>
        <Box
          display={"flex"}
          justifyContent={"center"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <img src={require("./../assets/logo.png")} width={300} />
          <Typography
            variant="overline"
            fontSize={"32px"}
            className="playfair-font"
          >
            Privacy Policy
          </Typography>
        </Box>
        <Container maxWidth="sm">
          <Paper elevation={3} style={{ padding: "20px" }}>
            <Typography variant="h5">Privacy Policy</Typography>
            <Typography variant="subtitle1">
              Last updated on 3rd February, 2022
            </Typography>

            <Typography variant="body1">
              This Privacy Policy (“Policy”) describes the policies and
              procedures on the collection, use, disclosure, and protection of
              your information when you use our website located at hungrezy.in,
              or the Hungrezy mobile application (collectively, Hungrezy
              Platform”) made available by Pernicia Consultancy Private Limited
              (Hungrezy, “Company”, “we”, “us” and “our”), a private company
              established under the laws of India having its registered office
              at SCF 88, Govind Vihar, Baltana,Zirakpur,Punjab - 140603.
            </Typography>

            <Typography variant="body1">
              The terms “you” and “your” refer to the user of the Hungrezy
              Platform. The term “Services” refers to any services offered by
              Hungrezy, whether on the Hungrezy Platform or otherwise.
            </Typography>

            {/* Continue with the rest of the content... */}

            <Typography variant="body1">
              Further, please note that the Hungrezy Platform stores your data
              with the cloud platform of Amazon Web Services provided by Amazon
              Web Services, Inc., which may store this data on their servers
              located outside of India. Amazon Web Services has security
              measures in place to protect the loss, misuse and alteration of
              the information, details of which are available at{" "}
              <Link
                href="https://aws.amazon.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://aws.amazon.com/
              </Link>
              .
            </Typography>

            <Typography variant="body1">
              The privacy policy adopted by Amazon Web Services is detailed in{" "}
              <Link
                href="https://aws.amazon.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://aws.amazon.com/privacy
              </Link>
              . In the event you have questions or concerns about the security
              measures adopted by Amazon Web Services, you can contact their
              data protection/privacy team/legal team or the designated
              grievance officer at these organizations, whose contact details
              are available in their privacy policy, or you can also write to
              our Grievance Officer at the address provided above.
            </Typography>
          </Paper>
        </Container>
      </Stack>
    </Stack>
  );
};

export default PrivacyPolicy;
