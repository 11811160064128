import React, { useState, useRef } from "react";
import {
  TextField,
  Button,
  FormControl,
  Container,
  Grid,
  Avatar,
  IconButton,
  Box,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
} from "@mui/material"; // Make sure to import your actual interfaces
import { MealType, MenuItem } from "../interfaces";
import { restService } from "../services/rest.service";
import { Create } from "@mui/icons-material";

interface MenuItemFormProps {
  onSubmit: (formData: MenuItem) => void;
  initialData?: MenuItem;
  sellerId: number;
}

const MenuItemForm: React.FC<MenuItemFormProps> = ({
  onSubmit,
  initialData,
  sellerId,
}) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<MenuItem>(
    initialData || {
      name: "",
      description: "",
      costPrice: 0,
      marginPercentage: 0,
      mealType: MealType.VEG,
      category: "",
      subCategory: "",
      image: "",
      id: 1,
      inStock: false,
    }
  );
  const inputref = useRef<any>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleMealTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, mealType: e.target.value as MealType });
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Assuming the API returns the image URL after upload
    // You can replace this logic with your actual image upload logic
    const file = e.target.files?.[0];
    if (file) {
      const image = URL.createObjectURL(file);
      setFormData((prev) => ({ ...prev, image }));
    }
    if (file) {
      uploadImage(file)
        .then((imageUrl) =>
          setFormData((prev) => ({ ...prev, image: imageUrl }))
        )
        .catch((error) => console.error("Image upload error:", error));
    }
  };
  const handleImageButton = () => {
    if (inputref?.current) {
      inputref?.current?.click();
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  const uploadImage = async (file: File): Promise<string> => {
    // Implement your image upload logic here
    // For now, simulate image upload by using a timeout
    try {
      setLoading(true);
      const response = await restService.uploadImage(file, sellerId);
      return Promise.resolve(response.data.url);
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={6}>
            <Box
              sx={{
                position: "relative",
                width: "fit-content",
                margin: "0 auto",
              }}
            >
              <Avatar src={formData.image} sx={{ width: 100, height: 100 }}>
                Image
              </Avatar>
              <IconButton
                onClick={handleImageButton}
                size="small"
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 10,
                  zIndex: 1000,
                  backgroundColor: "primary.main",
                }}
                color="warning"
              >
                <Create sx={{ fontSize: "10px" }} />
              </IconButton>
              {loading && (
                <CircularProgress
                  size={100}
                  thickness={1}
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 1,
                  }}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend">Meal Type</FormLabel>
              <RadioGroup
                row
                aria-label="mealType"
                name="mealType"
                value={formData.mealType}
                onChange={handleMealTypeChange}
              >
                <FormControlLabel
                  value={MealType.VEG}
                  control={<Radio size="small" />}
                  label="Veg"
                />
                <FormControlLabel
                  value={MealType.NON_VEG}
                  control={<Radio size="small" />}
                  label="Non-Veg"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Name"
              name="name"
              size="small"
              value={formData.name}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Description"
              name="description"
              size="small"
              value={formData.description}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Cost Price"
              name="costPrice"
              type="number"
              size="small"
              value={formData.costPrice}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Margin Percentage"
              name="marginPercentage"
              type="number"
              size="small"
              value={formData.marginPercentage}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Selling Price"
              name="sellingPrice"
              type="number"
              size="small"
              value={
                +formData.costPrice +
                (+formData.costPrice * +formData.marginPercentage) / 100
              }
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              size="small"
              label="Category"
              name="category"
              value={formData.category}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              fullWidth
              label="Subcategory"
              name="subCategory"
              value={formData.subCategory}
              onChange={handleInputChange}
            />
          </Grid>
          <input
            ref={inputref}
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            style={{ display: "none" }}
          />
          <Grid item xs={12}>
            <Button fullWidth type="submit" variant="contained" color="primary">
              {initialData ? "Update" : "Create"} Item
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default MenuItemForm;
