// ProtectedRoute.tsx

import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { RootState } from "../store/store";
import SideNav from "../components/SideNav";
import { Container } from "@mui/material";

const PrivateRoute = () => {
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );
  return isAuthenticated ? (
    <Container maxWidth="xl" disableGutters sx={{ position: "relative" }}>
      <SideNav />
    </Container>
  ) : (
    <Navigate to="/" />
  );
};

export default PrivateRoute;
