// store.ts
import { configureStore } from "@reduxjs/toolkit";
import authReducer, { AuthState } from "./auth.slice";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import sellerReducer, { SellerState } from "./sellers.slice";
import { driverState } from "./drivers.slice";
import driverReducer from "./drivers.slice";
import { promoState } from "./promo.slice";
import promoReducer from "./promo.slice";
import orderReducer from "./order.slice";
import { orderState } from "./order.slice";



const authPersistConfig = {
  key: "auth",
  storage,
};

const persistedAuthReducer = persistReducer(authPersistConfig, authReducer);

const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
    seller: sellerReducer,
    driver: driverReducer,
    promotion: promoReducer,
    order:orderReducer
  },
});

export const persistor = persistStore(store);
export default store;
export type RootState = {
  auth: AuthState;
  seller: SellerState;
  driver: driverState;
  promotion: promoState;
  order:orderState
};
