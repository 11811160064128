// src/ImageDescriptionColumn.tsx
import React from "react";
import { Container, Typography, Paper, Box } from "@mui/material";

interface ImageDescriptionColumnProps {
  imageSrc: string;
  description: string;
  step?: number;
  isEven?: boolean;
  title?: string;
}

const ImageDescriptionColumn: React.FC<ImageDescriptionColumnProps> = ({
  isEven,
  title,
  imageSrc,
  description,
  step,
}) => {
  return (
    <Container>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "16px",
          mt: isEven === true ? "100px" : "0px",
        }}
      >
        <Box>
          <img
            src={imageSrc}
            alt="Description"
            style={{ maxWidth: "150px", height: "auto" }}
          />
        </Box>
        <Box mt={2} textAlign={"center"}>
          {step && (
            <Typography variant="h6" sx={{ color: "warning.main" }}>
              Step : {step}
            </Typography>
          )}
          {title && (
            <Typography
              variant="h6"
              sx={{ color: "primary.main" }}
              className="poppins-semibold"
            >
              {title}
            </Typography>
          )}
          <Typography variant="body1" className="poppins-medium">
            {description}
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default ImageDescriptionColumn;
