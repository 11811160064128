import React, { useState } from "react";
import { SellerAllData } from "../interfaces";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { restService } from "../services/rest.service";
import { useNavigate } from "react-router-dom";

const CreateSeller = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<any>({
    phone: "",
    email: "",
    language: "",
    name: "",
    restaurantName: "",
    tagLine: "",
    instaProfile: "",
    address: "",
    serveType: "",
    storeType: "",
    bestChoices: [],
    restaurantImage: "",
    coverImage: "",
    maxOrderAmount: 0,
    maxPreprationTime: 0,
    minOrderAmount: 0,
  });

  const handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | { name?: string; value: unknown }
    >
  ) => {
    const { name, value } = event.target;
    setFormData((prevState: any) => ({
      ...prevState,
      [name as string]: value,
    }));
  };

  const handleSubmit = () => {
    restService.createNewSeller(formData).then((res) => {
      console.log(res.data);
      navigate("/admin/restaurants");
    });
  };

  return (
    <Box>
      <Typography variant="h4" textAlign={"center"} mb={2}>
        Create New Restaurant
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Language"
            name="language"
            value={formData.language}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Restaurant Name"
            name="restaurantName"
            value={formData.restaurantName}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Tag Line"
            name="tagLine"
            value={formData.tagLine}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Instagram Profile"
            name="instaProfile"
            value={formData.instaProfile}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Address"
            name="address"
            value={formData.address}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Serve Type"
            name="serveType"
            value={formData.serveType}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Store Type"
            name="storeType"
            value={formData.storeType}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Restaurant Image"
            name="restaurantImage"
            value={formData.restaurantImage}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Cover Image"
            name="coverImage"
            value={formData.coverImage}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            type="number"
            label="Max Order Amount"
            name="maxOrderAmount"
            value={formData.maxOrderAmount}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            type="number"
            label="Max Preparation Time"
            name="maxPreprationTime"
            value={formData.maxPreprationTime}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            type="number"
            label="Min Order Amount"
            name="minOrderAmount"
            value={formData.minOrderAmount}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Submit
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateSeller;
