import React from "react";
import { Box, Card, CardContent, List, Typography } from "@mui/material";

interface MenuCardProps {
  title: string;
  details: string[][];
}

const VerificationMenuCard: React.FC<MenuCardProps> = ({ title, details }) => (
  <Card variant="outlined" sx={{ width: 575, mb: 2 }}>
    <CardContent>
      <Typography variant="h6" mb={2}>
        {title}
      </Typography>
      <List>
        {details.map(([primary, secondary]) => (
          <Box key={primary}>
            {primary === "Description" && (
              <Typography fontWeight="bold">{primary}</Typography>
            )}
            <Typography>{secondary}</Typography>
          </Box>
        ))}
      </List>
    </CardContent>
  </Card>
);

export default VerificationMenuCard;
