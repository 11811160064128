import { useState } from "react";
import { AppBar, Button, Toolbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { login, logout } from "../store/auth.slice";
import { RootState } from "../store/store";
import AdminLogin from "./AdminLogin";

const Header = () => {
  const user = useSelector((state: RootState) => state.auth.user);
  const [openDialog, setOpenDialog] = useState(false);
  const setOpenDialogHandler = function () {
    setOpenDialog(true);
  };
  const setCloseDialogHandler = function () {
    setOpenDialog(false);
  };
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );
  const dispath = useDispatch();
  return (
    <AppBar
      position={isAuthenticated ? "fixed" : "static"}
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Toolbar sx={{ ml: "auto", gap: "10px" }}>
        {!isAuthenticated ? (
          <>
            <Button color="inherit">Ride With Us</Button>
            <Button color="inherit">Partner With Us</Button>
            <Button
              color="inherit"
              variant="outlined"
              onClick={setOpenDialogHandler}
            >
              Login / Sign Up
            </Button>
          </>
        ) : (
          <>
            <Button color="inherit">{user?.name}</Button>
            <Button
              color="inherit"
              variant="outlined"
              onClick={() => dispath(logout())}
            >
              Log Out
            </Button>
          </>
        )}
        <AdminLogin open={openDialog} handleClose={setCloseDialogHandler} />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
