import React, { useState, useEffect } from "react";
import Fab from "@mui/material/Fab";
import { ArrowUpwardRounded } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";

const ScrollTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsVisible(scrollTop > 300);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Fab
      size="medium"
      color="primary"
      aria-label="scroll back to top"
      sx={{
        position: "fixed",
        bottom: 16,
        right: 16,
        display: isVisible ? "block" : "none",
        lineHeight: "1",
      }}
      onClick={handleScrollTop}
    >
      <ArrowUpwardRounded />
    </Fab>
  );
};

export default ScrollTopButton;
