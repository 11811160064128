import React, { useRef, useState } from "react";
import Paper from "@mui/material/Paper";
import {
  IconButton,
  Box,
  Stack,
  FormLabel,
  CircularProgress,
} from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { UploadFileRounded } from "@mui/icons-material/";
import { restService } from "../services/rest.service";

const ImageUploader = ({
  imageUrl = "",
  onUpload,
  downloadable,
  disabled,
  label,
  restaurantId,
}: {
  restaurantId?: number;
  imageUrl?: string;
  onUpload?: (file: any) => void;
  downloadable?: boolean;
  disabled?: boolean;
  label?: string;
}) => {
  const [selectedImageUri, setSelectedImageUri] = useState(imageUrl);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const fileInputRef = useRef<any>(null);
  const handleButtonClick = () => {
    if (downloadable || disabled) {
      return;
    }
    if (fileInputRef?.current) {
      fileInputRef?.current?.click();
    }
  };

  const handleFileChange = (event: any) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const image = URL.createObjectURL(selectedFile);
      setSelectedImageUri(image);
    } else {
      setSelectedImageUri(imageUrl);
    }
    setSelectedImage(selectedFile);
  };
  const handleUpload = () => {
    if (selectedImage && restaurantId) {
      setLoading(true);
      restService
        .uploadImage(selectedImage, restaurantId)
        .then((res) => {
          if (onUpload) {
            onUpload(res.data.url);
          }
          setLoading(false);
          setSuccess(true);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  return (
    <Stack alignItems={"center"} justifyContent={"center"}>
      {label && (
        <FormLabel component="legend" sx={{ mb: 1 }}>
          {label}
        </FormLabel>
      )}
      <Paper
        sx={{
          width: "200px",
          display: "flex",
          height: "200px",
          alignItems: "center",
          justifyContent: "center",
          margin: "0 auto",
          opacity: loading ? 0.5 : 1,
          position: "relative",
        }}
      >
        {loading && (
          <CircularProgress
            sx={{
              zIndex: 1000,
              position: "absolute",
              top: "45%",
            }}
          />
        )}
        <input
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={handleFileChange}
        />
        {selectedImageUri ? (
          <IconButton
            onClick={handleButtonClick}
            disableFocusRipple
            disableRipple
          >
            <img
              src={selectedImageUri}
              alt="selected"
              width={"200px"}
              height={"200px"}
              style={{ borderRadius: "5px" }}
            />
          </IconButton>
        ) : (
          !downloadable && (
            <IconButton onClick={handleButtonClick} size={"large"}>
              <PhotoCameraIcon sx={{ fontSize: "50px" }} />
            </IconButton>
          )
        )}
      </Paper>
      {selectedImage && (
        <Stack justifyContent={"center"} alignItems={"center"} mt={1}>
          <IconButton onClick={handleUpload}>
            <UploadFileRounded sx={{ fontSize: "30px" }} />
          </IconButton>
        </Stack>
      )}
      {downloadable && (
        <Stack justifyContent={"center"} alignItems={"center"} mt={1}>
          <IconButton>
            <DownloadForOfflineIcon sx={{ fontSize: "30px" }} />
          </IconButton>
        </Stack>
      )}
    </Stack>
  );
};

export default ImageUploader;
