import React, { useState } from "react";
import { Dialog, DialogContent, Link, Typography, Stack } from "@mui/material";
import LoginForm from "./LoginForm";
import SignupForm from "./SignUpForm";

interface AdminLoginProps {
  open: boolean;
  handleClose: () => void;
}

const AdminLogin: React.FC<AdminLoginProps> = ({ open, handleClose }) => {
  const [activeForm, setActiveForm] = useState("login");

  const activeFormHandler = () => {
    if (activeForm === "login") return <LoginForm handleClose={handleClose} />;
    if (activeForm === "signup")
      return <SignupForm handleClose={handleClose} />;
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs">
      <DialogContent>
        {activeFormHandler()}
        {activeForm === "login" && (
          <Stack direction="row" gap={1}>
            <Typography>Don't have an account?</Typography>
            <Link onClick={() => setActiveForm("signup")}>Sign Up</Link>
          </Stack>
        )}
        {activeForm === "signup" && (
          <Stack direction="row" gap={1}>
            <Typography>Already have an account?</Typography>
            <Link onClick={() => setActiveForm("login")}>Login</Link>
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AdminLogin;
