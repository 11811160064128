import axios, { AxiosResponse, AxiosError } from "axios";
import store from "../store/store";
import {
  DriversResponse,
  MenuItem,
  MenuItemPayload,
  SellerAllData,
  SellerCharges,
  SellersApiResponse,
  chargesResponse,
} from "../interfaces";

const LOGIN_URL = "admin/login";
const SIGNUP_URL = "admin/register";
const VERIFY_DRIVER = "admin/verify/driver/{id}";
const VERIFY_SELLER = "admin/verify/seller/{id}";
const FORGET_PASSWORD_URL = "admin/forget-password";
const CHANGE_PASSWORD_URL = "admin/change-password";
const GET_SELLER_URL = "admin/sellers";
const GET_DRIVER_URL = "admin/drivers";
const DELETE_SELLER_URL = "admin/seller/{id}";
const DELETE_DRIVER_URL = "admin/driver/{id}";
const FILE_UPLOAD_API = "common/uploadFile";
const UPDATE_SELLER = "seller/update";
const UNVERIFIED_SELLER_COUNT = "admin/sellers/unverified/count";
const VERIFIED_SELLER_COUNT = "admin/sellers/verified/count";
const UNVERIFIED_DRIVER_COUNT = "admin/drivers/unverified/count";
const VERIFIED_DRIVER_COUNT = "admin/drivers/verified/count";
const CREATE_MENUITEM = "menus/create/{sellerId}";
const UPDATE_MENUITEM = "menus/{type}/{id}";
const GET_MENUS = "menus/{id}";
const GET_ORDERS = "admin/orders";
const SELLER_CHARGES = "admin/seller/charges/{id}";
const CREATE_SELLER = "admin/create/seller";
const SELLER_ADDRESS = "seller/address/{id}";
const REJECT_ORDER= "admin/order/reject/{id}";


class RestService {
  private devUrl = "https://hungrezybackend-development.up.railway.app/";
  private prodUrl = "https://hungrezybackend-production.up.railway.app/";
  private localUrl = "http://localhost:4000/"; // Add your base URL here

  private axiosInstance = axios.create({
    baseURL: this.devUrl,
    headers: {
      "Content-Type": "application/json",
    },
  });

  constructor() {
    this.setupInterceptors();
  }

  private setupInterceptors() {
    this.axiosInstance.interceptors.request.use(
      (config) => {
        const token = store.getState().auth.token; // Assuming your auth state has a user with a token
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    this.axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => response,
      (error: AxiosError) => {
        return Promise.reject(error);
      }
    );
  }

  async loginApi(email: string, password: string): Promise<AxiosResponse> {
    try {
      const loginData = { email, password };
      const response = await this.axiosInstance.post(LOGIN_URL, loginData);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async signupApi(
    name: string,
    email: string,
    password: string,
    secret: string
  ): Promise<AxiosResponse> {
    try {
      const signupData = { name, email, password, secret };
      const response = await this.axiosInstance.post(SIGNUP_URL, signupData);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async forgetPassword(): Promise<AxiosResponse> {
    try {
      const response = await this.axiosInstance.post(FORGET_PASSWORD_URL);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async changePassword(): Promise<AxiosResponse> {
    try {
      const response = await this.axiosInstance.post(CHANGE_PASSWORD_URL);
      return response;
    } catch (error) {
      throw error;
    }
  }
  async userCountApi(): Promise<AxiosResponse<any>[]> {
    try {
      const response = await Promise.all([
        this.axiosInstance.get(UNVERIFIED_SELLER_COUNT),
        this.axiosInstance.get(VERIFIED_SELLER_COUNT),
        this.axiosInstance.get(UNVERIFIED_DRIVER_COUNT),
        this.axiosInstance.get(VERIFIED_DRIVER_COUNT),
      ]);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getAllSellers(
    page: number,
    limit: number,
    isVerified?: boolean,
    hasMenuImage?: boolean,
    hasAllDocuments?: boolean
  ): Promise<SellersApiResponse> {
    try {
      const params: Record<string, any> = {
        page,
        limit,
      };

      if (isVerified !== undefined) {
        params.isVerified = isVerified;
      }

      if (hasMenuImage) {
        params.hasMenuImage = hasMenuImage;
      }

      if (hasAllDocuments) {
        params.hasAllDocuments = hasAllDocuments;
      }
      const response = await this.axiosInstance.get(GET_SELLER_URL, {
        params,
      });
      return response.data as SellersApiResponse;
    } catch (error) {
      throw error;
    }
  }
  async getAllDrivers(
    page: number,
    limit: number,
    isVerified?: boolean
  ): Promise<DriversResponse> {
    try {
      const params: Record<string, any> = {
        page,
        limit,
      };

      if (isVerified !== undefined) {
        params.isVerified = isVerified;
      }
      const response = await this.axiosInstance.get(GET_DRIVER_URL, {
        params,
      });
      return response.data as DriversResponse;
    } catch (error) {
      throw error;
    }
  }

  async deleteSeller(id: number): Promise<AxiosResponse> {
    try {
      const response = this.axiosInstance.delete(
        DELETE_SELLER_URL.replace("{id}", String(id))
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
  async getSeller(id: number): Promise<AxiosResponse> {
    try {
      const response = this.axiosInstance.get(
        DELETE_SELLER_URL.replace("{id}", String(id))
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getDriver(id: number): Promise<AxiosResponse> {
    try {
      const response = this.axiosInstance.get(
        DELETE_DRIVER_URL.replace("{id}", String(id))
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async uploadImage(file: any, id: number): Promise<AxiosResponse> {
    console.log(file);
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = this.axiosInstance.post(FILE_UPLOAD_API, formData, {
        params: {
          id,
        },
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          type: "formData",
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  async updateSeller(
    sellerDate: Partial<SellerAllData>
  ): Promise<AxiosResponse> {
    try {
      const response = this.axiosInstance.post(UPDATE_SELLER, sellerDate);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async createMenuItem(
    menuItem: MenuItemPayload,
    sellerId: number
  ): Promise<AxiosResponse> {
    try {
      const response = await this.axiosInstance.post(
        CREATE_MENUITEM.replace("{sellerId}", String(sellerId)),
        menuItem
      );

      return response;
    } catch (error) {
      throw error;
    }
  }

  async updateMenuItem(
    menuItem: Partial<MenuItem>,
    itemType: string
  ): Promise<AxiosResponse> {
    try {
      const response = await this.axiosInstance.patch(
        UPDATE_MENUITEM.replace("{type}", itemType).replace(
          "{id}",
          String(menuItem.id)
        ),
        menuItem
      );

      return response;
    } catch (error) {
      throw error;
    }
  }
  async getMenuItems(id: number): Promise<AxiosResponse> {
    try {
      const response = await this.axiosInstance.get(
        GET_MENUS.replace("{id}", String(id))
      );

      return response;
    } catch (error) {
      throw error;
    }
  }

  async verifyDriver(id: number, status: boolean): Promise<AxiosResponse> {
    try {
      const response = await this.axiosInstance.put(
        VERIFY_DRIVER.replace("{id}", String(id)),
        { status }
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
  async verifySeller(id: number, status: boolean): Promise<AxiosResponse> {
    try {
      const response = await this.axiosInstance.put(
        VERIFY_SELLER.replace("{id}", String(id)),
        { status }
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
  async getAllOrders(): Promise<AxiosResponse> {
    try {
      const response = await this.axiosInstance.get(GET_ORDERS);
      return response;
    } catch (error) {
      throw error;
    }
  }
  async updateSellerCharges(
    sellerId: number,
    charges: SellerCharges
  ): Promise<AxiosResponse> {
    try {
      const response = await this.axiosInstance.post(
        SELLER_CHARGES.replace("{id}", String(sellerId)),
        {
          ...charges,
          deliveryfeesfree: +charges.deliveryfeesfree,
          gst: +charges.gst,
          packaging: +charges.packaging,
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
  async getSellerCharges(sellerId: number): Promise<chargesResponse> {
    try {
      const response = await this.axiosInstance.get(
        SELLER_CHARGES.replace("{id}", String(sellerId))
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async createNewSeller(data: any): Promise<any> {
    try {
      const response = await this.axiosInstance.post(CREATE_SELLER, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async addSellerAddress(sellerId: string, address: any): Promise<any> {
    try {
      const response = await this.axiosInstance.post(
        SELLER_ADDRESS.replace("{id}", String(sellerId)),
        address
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async rejectOrder(orderId:number,reason:string){
    try {
      const response = await this.axiosInstance.post(
        REJECT_ORDER.replace("{id}", String(orderId)),
        {reason}
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

const restService = new RestService();
export { restService };
