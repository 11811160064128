import React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Grid, List, ListItem, Typography } from "@mui/material";
import { Promo } from "../interfaces";
interface PromoCardProps {
  promoData: Promo[];
  openUpdateDialog: (promo: Promo) => void;
  deletePromo: (id: number) => void;
}

const PromoCard: React.FC<PromoCardProps> = ({
  promoData,
  openUpdateDialog,
  deletePromo,
}) => {
  const editHandler = (id: number) => {
    const promoToEdit = promoData.find((promo) => promo.id === id);
    if (promoToEdit) {
      openUpdateDialog(promoToEdit);
    }
  };

  const deleteHandler = (id: number) => {
    deletePromo(id);
  };
  return (
    <Grid
      container
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
        gap: "16px",
      }}
    >
      {promoData.map((promo) => (
        <Card
          key={promo.id}
          sx={{
            maxWidth: 345,
            marginBottom: 2,
          }}
        >
          <CardHeader
            action={
              <>
                <IconButton
                  aria-label="edit"
                  onClick={() => editHandler(promo.id)}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  onClick={() => deleteHandler(promo.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </>
            }
            title={promo.promoCode}
            subheader={`Expiry Date: ${promo.expiryDate}`}
          />
          <CardContent>
            <List
              sx={{
                listStyleType: "disc",
                listStylePosition: "inside",
              }}
            >
              <ListItem sx={{ display: "list-item" }}>
                Discount {promo.discount}%
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                Min Order Amount: Rs. {promo.minAmount}
              </ListItem>
            </List>
          </CardContent>
          <CardActions style={{ justifyContent: "center" }}>
            <Button>View Restaurants</Button>
          </CardActions>
        </Card>
      ))}
      {promoData.length === 0 && <Typography>No promos available</Typography>}
    </Grid>
  );
};

export default PromoCard;
