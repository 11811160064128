import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import RestaurantOutlinedIcon from "@mui/icons-material/RestaurantOutlined";
import DeliveryDiningOutlinedIcon from "@mui/icons-material/DeliveryDiningOutlined";
import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";

import Header from "./Header";
import {
  Outlet,
  useNavigate,
  useNavigation,
  useLocation,
} from "react-router-dom";

const drawerWidth = 240;

const drawersList = [
  { name: "Dashboard", path: "/admin", icon: <SpaceDashboardIcon /> },
  {
    name: "Orders",
    path: "/admin/orders",
    icon: <ShoppingBagIcon />,
  },
  {
    name: "Restaurants",
    path: "/admin/restaurants",
    icon: <RestaurantOutlinedIcon />,
  },
  {
    name: "Drivers",
    path: "/admin/drivers",
    icon: <DeliveryDiningOutlinedIcon />,
  },
  {
    name: "Promotions",
    path: "/admin/promotions",
    icon: <CardGiftcardOutlinedIcon />,
  },
];

export default function SideNav() {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Box display={"flex"}>
      <CssBaseline />
      <Header />
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />
        <Box>
          <List>
            {drawersList.map((item, index) => (
              <ListItem key={item.name} disablePadding>
                <ListItemButton
                  selected={item.path === location?.pathname}
                  onClick={() => navigate(item.path)}
                  sx={{
                    background: "primary.main",
                  }}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 2, bgcolor: "background.default" }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}
